

const envRouter = [
  {
    name: 'envRoot',
    path: '/env/testenv',
    components: {
      default: function () {
        return import('../pages/env/testenv/EnvManagement.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    meta: ''
  },
  { path: '/env', redirect: '/env/testenv' }
]

export {
  envRouter
}
