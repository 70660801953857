// 1. 引入
import axios from "axios";
import { Message } from "view-ui-plus";
import store from "../store";
import router from "../router";

const config = {
  // 每次请求的协议、IP地址。  设置该配置后，每次请求路径都可以使用相对路径，例如"/admin/login"
  baseURL: "http://demo.teamcat.cn",

  // 请求超时时间
  timeout: 100000,
  // 每次请求携带cookie
  withCredentials: true,
};
// 2. 创建实例
const instance = axios.create(config);

// 3. 配置信息

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    config.headers["AppName"] = "Teamvision-Web";
    let token = localStorage.getItem("token");
    let defaultParams = {
      ProductSpace: store.state.productSpace,
    };
    // 自定义header信息（比如token）
    config.headers["ProductSpace"] = store.state.productSpace;
    // 默认值与接口传来的参数进行合并（注：接口参数与默认值不可重复）
    if (token) {
      // 符合判断条件，做出响应处理，例如携带token
      config.headers["Authorization"] = token;
      config.headers["Content-Type"] = "application/json";
      config.data = Object.assign(defaultParams, config.data);
      // 最后返回 config 代表继续发送请求
    }
    return config;
  },
  (err) => Promise.reject(),
);

// 请求拦截
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    let code = err.response.data.code;
    console.log(err.response);
    if (code === "undefined") {
      return err.response;
    } else {
      console.log(code === 403);
      if (code === 403) {
        localStorage.clear();
        sessionStorage.removeItem("userInfo");
        // Message.error({
        //   content: err.response.data.message,
        //   duration: 1000,
        //   closeable: true
        // })
        console.log(code);
        // 清除token
        // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页
        console.log(router.currentRoute);
        Message.error({
          content: "权限获取失败",
          duration: 10,
          closeable: true,
        });
        setTimeout(() => {
          router.replace({
            path: "/login",
            query: {
              redirect: router.currentRoute.fullPath,
            },
          });
        }, 1000);
      } else {
        return err.response;
      }
    }
    return Promise.reject(err.response);
  },
);

// 4. 导出
export default instance;
