<template>
  <div>
<!--  <div class="app-body-header-bar-default">-->
<!--          <Row>-->
<!--            <Col :lg="16" :md="14" :sm="8" :xs="2">-->
<!--            <div class="app-body-header-leftbar-default">-->
<!--              <ul class="app-body-head-menu">-->

<!--              </ul>-->
<!--            </div>-->
<!--            </Col>-->
<!--            <Col :lg="4" :md="4" :sm="8" :xs="10">-->
<!--            <div style="padding-top:15px;height: inherit">-->
<!--              <AutoComplete @on-focus="loadMyProject"  @on-search="filterProjectList" size="small"  @on-change="keyworkChange" :transfer="true"  v-model="projectSearchKey" icon="ios-search" placeholder="输入项目名称，查找项目！" style="width:100%">-->
<!--                <div style="overflow-y: scroll;max-height:400px;">-->
<!--                  <div class="project-search-item" v-for="item in myProjectList" v-if="item.Display">-->
<!--                    <router-link :to="'/project/'+ item.id +'/test/case-group'" style="color:inherit">-->
<!--                      <span class="project-search-item-title"><Avatar :src="item.PBAvatar" /></span>-->
<!--                      <span class="project-search-item-title">{{ item.PBTitle }}</span>-->
<!--                    </router-link>-->

<!--                  </div>-->
<!--                </div>-->
<!--              </AutoComplete>-->
<!--            </div>-->
<!--            </Col>-->
<!--            <Col :lg="4" :md="6" :sm="8" :xs="12">-->
<!--            <div class="app-body-header-rightbar-default" style="padding-top: 10px;height: inherit">-->
<!--              <Button type="success" to="/project/create" style="background-color: #32be77;color: #f0f5ff" shape="circle" icon="md-add"></Button>-->
<!--&lt;!&ndash;              <span @click="addProject" class="cursor-hand"><Avatar style="background-color: #32be77;" icon="md-add" /></span>&ndash;&gt;-->
<!--            </div>-->
<!--          </Col>-->
<!--          </Row>-->
<!--  </div>-->
  </div>
</template>

<script>
import store from '../../store/index.js'
import { mapMutations } from 'vuex'

export default {
  name: 'CIHead',
  props: ['menuItem'],
  data () {
    return {
      myProjects: [],
      projectSearchKey: ''
    }
  },
  computed: {
    myProjectList: function () {
      return this.myProjects
    }
  },
  methods: {
    ...mapMutations('project', ['setProjectCreateDialogShow', 'setProjectSearchKey']),

    loadMyProject: function () {
      if(this.myProjects.length===0){
        this.$axios.get('/api/project/list?extinfo=0&my=1').then(response => {
          this.myProjects = response.data.result
        }, response => {
        })
      }
    },
    filterProjectList: function (value) {
      this.initProjectDisplayStatus()
      this.setProjectSearchKey(value)
      for (let i = 0; i < this.myProjects.length; i++) {
        let temp = this.myProjects[i]
        if (temp.PBTitle.toUpperCase().indexOf(value.toUpperCase()) < 0) {
          temp.Display = false
        }
      }
    },

    keyworkChange: function (value) {
      if (value === '') {
        this.initProjectDisplayStatus()
        this.setProjectSearchKey(value)
      }
    },
    initProjectDisplayStatus: function () {
      for (let i = 0; i < this.myProjects.length; i++) {
        this.myProjects[i].Display = true
      }
    },

    addProject: function () {
      this.setProjectCreateDialogShow(true)
    }

  },
  created: function () {
//    this.loadMyProject()
  },
  components: {},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../../components/layout/appBody';
@import '../../components/layout/appHead';
@import '../../assets/teamvision/global/less/global';
.project-search-item{
  padding: 0px 0;
  border-bottom: 1px solid #F6F6F6;
  height:40px;
}

.project-search-item-title{
  color:#5578aa;
  padding-left: 20px;
}

</style>
