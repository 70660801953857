
import { createRouter, createWebHistory } from 'vue-router'

import {apiRouter} from './interface'
import {envRouter} from './env'
import {projectRouter} from './project'
import {homeRouter} from './home'
import { ciRouter } from './ci'
import { systemAdminRouter } from './system-admin'
import { userCenterRouter } from './user-center'
import { loginoutRouter } from './login-out'
import { testingRouter } from './testing'
import { knowledgeRouter } from './knowledge'


const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'is-active',
  routes: [
    ...apiRouter,
    ...projectRouter,
    ...homeRouter,
    ...ciRouter,
    ...systemAdminRouter,
    ...userCenterRouter,
    ...loginoutRouter,
    ...envRouter,
    ...testingRouter,
    ...knowledgeRouter
  ]
})

export default router;
