import Vue from 'vue'
import Vuex from 'vuex'


const state = {
  homeDynamicMenu: { text: '', path: '', icon: '', parameters: {},show: false},
  headMenu: {
    newObject: true,
    itemViewMode: false,
    filterMenu: false,
    exportIssue: false
  },
}
const getters = {
  homeDynamicMenu (state) {
    return state.homeDynamicMenu
  },

  headMenu (state) {
    return state.headMenu
  }


}
const mutations = {
  setHomeDynamicMenu (state,dynamicMenu) {
    state.homeDynamicMenu = dynamicMenu
  },

  setHeadMenu(state,headMenu) {
    state.headMenu = headMenu
  }

}
const actions = {

}
const modules = {}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
  modules
}
