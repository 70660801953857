
import store from '../store/index'
import EmptyHead from '../components/layout/EmptyBodyHead.vue'

const ciRouter = [
  {
    name: 'ciRoot',
    path: '/ci',
    redirect: '/ci/branch',
    meta: '',
    beforeEnter: (to, from, next) => {
      hideDynamicCIMenu()
      initHeadTool(true,true,true)
      next()
    },

  },
  {
    name: 'ciTaskFlow',
    path: '/ci/taskflow',
    components: {
      default: function () {
        return import('../pages/ci/CITaskFlowSummary.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      hideDynamicCIMenu()
      initHeadTool(true,true,false)
      next()
    }
  },

  {
    name: 'ciTaskHistory',
    path: '/ci/task/:taskID/history',
    components: {
      default: function () {
        return import('../pages/ci/citask/CITaskHistory.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      hideDynamicCIMenu()
      initHeadTool(false,true,false)
      next()
    }
  },

  {
    name: 'citask',
    path: '/ci/task',
    components: {
      default: function () {
        return import('../pages/ci/CITaskSummary.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      hideDynamicCIMenu()
      initHeadTool(true,true,true)
      next()
    }
  },

  {
    name: 'codelab',
    path: '/ci/codelab',
    components: {
      default: function () {
        return import('../pages/project/code-lab/ProjectGitRepoList.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      hideDynamicCIMenu()
      initHeadTool(true,true,true)
      next()
    }
  },

  {
    name: 'coderepo',
    path: '/ci/codelab/repo/:id',
    components: {
      default: function () {
        return import('../pages/project/code-lab/ProjectGitRepoDetail.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      hideDynamicCIMenu()
      initHeadTool(true,true,true)
      next()
    }
  },

  {
    name: 'cirelease',
    path: '/ci/release',
    components: {
      default: function () {
        return import('../pages/ci/release/Schedule.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      hideDynamicCIMenu()
      initHeadTool(true,true,true)
      next()
    }
  },


  {
    name: 'cibranch',
    path: '/ci/branch',
    components: {
      default: function () {
        return import('../pages/ci/code-branch/CICodeBranch.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      hideDynamicCIMenu()
      initHeadTool(false,true,false)
      next()
    }
  },

  {
    name: 'ciautocase',
    path: '/ci/autocase',
    components: {
      default: function () {
        return import('../pages/ci/autocase/CIAutoCase.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      hideDynamicCIMenu()
      initHeadTool(false,true,false)
      next()
    }
  },


  {
    name: 'ciTaskFlowItem',
    path: '/ci/taskflow/:flowID',
    components: {
      default: function () {
        return import('../pages/ci/citaskflow/ci_task_flow_item/CITaskFlowItem.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/citaskflow/ci_task_flow_item/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      initHeadTool(true,false,false)
      hideDynamicCIMenu()
      next()
    },
  },

  {
    name: 'ciTaskFlowHistory',
    path: '/ci/taskflow/:flowID/history',
    components: {
      default: function () {
        return import('../pages/ci/citaskflow/ci_task_flow_item/CITaskFlowHistory.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/citaskflow/ci_task_flow_item/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      initHeadTool(false,false,false)
      hideDynamicCIMenu()
      next()
    },
  },

  {
    name: 'ciSettingsGlobalVariable',
    path: '/ci/settings/global_variable',
    components: {
      default: function () {
        return import('../pages/ci/settings/GlobalVariable.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      initHeadTool(false,true,false)
      initDynamicCIMenu(to, '全局变量',true)
      next()
    }
  },

  {
    name: 'ciSettingsAgent',
    path: '/ci/settings/agent',
    components: {
      default: function () {
        return import('../pages/ci/settings/CIAgent.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      initHeadTool(true,true,false)
      initDynamicCIMenu(to, 'Agent',true)
      next()
    }
  },

  {
    name: 'ciSettingsTag',
    path: '/ci/settings/tag',
    components: {
      default: function () {
        return import('../pages/ci/settings/CITag.vue')
      },
      bodyhead: function () {
        return import('../pages/ci/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      initDynamicCIMenu(to,'标签管理',true)
      initHeadTool(false,true,false)
      next()
    },
  },

  {
    name: 'ciPackageDownload',
    path: '/ci/task/output/:outputID/download',
    components: {
      default: function () {
        return import('../pages/ci/citask/MobileDownloadPackage.vue')
      },
      bodyhead: EmptyHead
    },
    props: {default: true,bodyhead: true},
    meta: ''
  },

]

function initDynamicCIMenu (to,menuText,isshow) {
  let headMenu = {text: menuText,path: to.path,icon: 'fa-cogs',parameters: {},show: isshow}
  store.commit('ciglobal/setCIDynamicMenu',headMenu)
}

function hideDynamicCIMenu () {
  let headMenu = {text: '',path: '',icon: 'fa-cogs',parameters: {},show:false}
  store.commit('ciglobal/setCIDynamicMenu',headMenu)
}

function  initHeadTool (newButton,ciSettings,taskSearchBox) {
  let headTool = {newObject: newButton,setting: ciSettings,taskSearchBox:taskSearchBox}
  store.commit('ciglobal/setCIHeadToolShow',headTool)
}

export {
  ciRouter
}
