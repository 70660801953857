<template>
  <Layout style="padding-top: 2px;">
    <Sider :hide-trigger="false" @on-collapse="collapseSider" v-model="isCollapsed" :collapsed-width="60" :collapsible="true" :style="{background: '#f5f7f8'}">
<!--        <Header class="testing-menu-header">-->
<!--&lt;!&ndash;            <div class="testing-menu-header-title">测试管理</div>&ndash;&gt;-->
<!--        </Header>-->
        <Menu ref="testMeunu" :active-name="activeName"  :style="{height:(containerHeight)+'px','border-right':'none'}" :class="menuitemClasses" theme="light" width="auto">
          <MenuItem :to="'/project/'+projectID+'/test/case/summary'" name="case">
            <Icon type="ios-bulb"></Icon>
            <span>测试用例</span>
          </MenuItem>
          <MenuItem :to="'/project/'+projectID+'/test/review-conclusion'" name="review">
            <Icon type="ios-cafe"></Icon>
            <span>测试评审</span>
          </MenuItem>
          <MenuItem :to="'/project/'+projectID+'/test/plan/summary'" name="plan">
            <Icon type="ios-map"></Icon>
            <span>测试计划</span>
          </MenuItem>
          <MenuItem :to="'/project/'+projectID+'/test/report'" name="report">
            <Icon type="ios-egg"></Icon>
            <span>测试报告</span>
          </MenuItem>
          <MenuItem :to="'/project/'+projectID+'/test/autogroup/summary'" name="autogroup">
            <Icon type="ios-plane"></Icon>
            <span>自动化用例库</span>
          </MenuItem>
        </Menu>
        <template #trigger>
            <div class="custom-sider-trigger" :style="{width:siderWidth+'px'}" >
                <span v-show="isCollapsed">
                    <Icon @click="collapseSider(false)" size="24" type="ios-arrow-dropright-circle" />
                </span>
                <span v-show="!isCollapsed">
                    <Icon @click="collapseSider(true)"  size="24" type="ios-arrow-dropleft-circle" />
                </span>
            </div>
        </template>
    </Sider>
    <Content>
      <Layout>
        <Sider hide-trigger v-show="showLeftPanel"  :width="360">
          <router-view name="leftPanel" class="view animated"></router-view>
        </Sider>
        <Content>
          <router-view name="testingCotent" class="view animated"></router-view>
        </Content>
      </Layout>
    </Content>
  </Layout>

</template>

<script>
  import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
  import { ref, onMounted } from 'vue'
  import {Header} from "view-ui-plus";


  export default {
    name: 'projectTestingManagement',
    props: ['projectID','objID','groupID','caseID','version','planID'],
    data () {
      return {
        columnItemHeight: 200,
        viewMode: 'board',
        isCollapsed: false,
        menuWidth:160,
      }
    },
    computed: {
      ...mapGetters('projectglobal', ['projectVersion','objectChange']),
      ...mapGetters(['appBodyHeight','appBodyWidth']),
    containerHeight: function () {
      return this.appBodyHeight
    },

    menuitemClasses () {
        return [
          'menu-item',
          this.isCollapsed ? 'collapsed-menu' : ''
        ]
      },

    routerName: function () {
            return this.$route.fullPath
    },

    activeName: function (){
     let result = 'case'
      if(this.routerName.indexOf('case')>-1){
          result = 'case'
      }
        if(this.routerName.indexOf('review')>-1){
            result = 'review'
        }
        if(this.routerName.indexOf('plan')>-1){
            result = 'plan'
        }
        if(this.routerName.indexOf('report')>-1){
            result = 'report'
        }
        if(this.routerName.indexOf('report')>-1){
            result = 'report'
        }
        if(this.routerName.indexOf('autogroup')>-1){
            result = 'autogroup'
        }
      return result
    },

    actionName: function () {
      if (this.action) {
        return this.action
      }
      return 'test-case'
      },

    containerWidth: function () {
      return this.appBodyWidth
    },
      versionID: function () {
        if (this.projectVersion) {
          return this.projectVersion
        }
        return 0
      },

      testCaseExecute: function () {
        if (this.$route.params.testAction){
          return this.$route.params.testAction
        }
        return 0
      },

      testReportID: function () {
        console.log(this.$route.params)
        if (this.$route.params.action==='report' && this.$route.params.groupID ){
          return this.$route.params.groupID
        }
        return 0
      },

      project: function () {
        let result = 0
        if( this.projectID )
        {
           result = this.projectID
        }
        return result
      },
      showLeftPanel: function (){

        let result =  this.$route.name!=='projectTestPlanExec' && this.$route.name!=='projecttestReport'
        result = result && this.$route.name!=='projecttestReportDetail' && this.$route.name!=='projectTestCaseReviewConclusion'
        result = result && this.$route.name!=='projectTestCaseReviewConclusionDetail'
        result = result && this.$route.name!=='projectTestCaseReviewPage'
        result = result && this.$route.name!=='projecttestReportTemplate'
        return result&&!this.isCollapsed
      },
      siderWidth:function (){
        if(this.isCollapsed){
          return 60
        }else{
          return 200
        }
      }

    },
    methods:
      {
        ...mapMutations('projectglobal', ['setViewDialogShow', 'setObjectChange','setTestMenuCollapse','setHeadMenu']),
        ...mapMutations( ['setItemViewMode']),

        changeViewMode: function (value) {
          console.log(value)
        },

        handleOpen(key, keyPath) {
          console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
          console.log(key, keyPath);
        },

        collapseSider: function (value){
          this.isCollapsed = value
        },

        // expandMenu: function () {
        //   this.isCollapse = false
        //   this.setTestMenuCollapse(false)
        //
        // },
        //
        // collapseMenu: function () {
        //   this.isCollapse = true
        //   this.setTestMenuCollapse(true)
        // },

      },
    beforeRouteLeave: function (to, from,next) {
      if(from.name==='projectTestCaseGroup'){
        this.$confirm('正在离开本页面，本页面内所有未保存数据都会丢失', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 正常跳转
          next()
        }).catch(() => {
          // 如果取消跳转地址栏会变化，这时保持地址栏不变
          window.history.go(1)
        })
      }else{
        next()
      }
    },
    beforeRouteUpdate: function (to, from,next) {
      if(from.name==='projectTestCaseGroup'){
        this.$confirm('正在离开本页面，本页面内所有未保存数据都会丢失', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 正常跳转
          next()
        }).catch(() => {
          // 如果取消跳转地址栏会变化，这时保持地址栏不变
          window.history.go(1)
        })
      }else{
        next()
      }
    },
    created: function () {
      console.log(this.groupID)
    },
      onMounted: function () {
        this.$nextTick(() => {
            this.$refs.testMeunu.updateActiveName();
        });
    },
    watch: {
      versionID: function (value) {
      },

      isCollapse: function (value) {
        if(value===true){
          this.menuWidth= 70
        } else {
          this.menuWidth= 220
        }

      },
    },

    components: {
        Header
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

  .testCaseSectionTitle {
    display: flex;
    flex-shrink: 0;
    -webkit-box-align: center;
    align-items: center;
    height: 72px;
    padding: 0px 24px;
  }

  .testCaseectionFilter {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: -16px;
    height: 48px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px;
    flex: 0 0 48px;
    padding: 0px 24px;
  }

  .testCaseSectionControls {
    display: flex;
    flex-shrink: 0;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 40px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 0px 0px;
  }

  .testCaseSectionTreeView {
    overflow-y: auto;
    flex: 1 1 auto;
    padding: 8px 4px;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }

  .layout{
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
  }
  .layout-header-bar{
    background: #fff;
    box-shadow: 0 1px 1px rgba(0,0,0,.1);
  }
  .layout-logo-left{
    width: 90%;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    margin: 15px auto;
  }
  .menu-icon{
    transition: all .3s;
  }
  .rotate-icon{
    transform: rotate(-90deg);
  }
  .menu-item span{
    padding-left: 14px;
    display: inline-block;
    overflow: hidden;
    width: 160px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width .2s ease .2s;
  }
  .menu-item i{
    transform: translateX(0px);
    transition: font-size .2s ease, transform .2s ease;
    vertical-align: middle;
    font-size: 16px;
  }
  .collapsed-menu span{
    width: 0px;
    transition: width .2s ease;
  }
  .collapsed-menu a{
    padding-left: 14px;
  }
  .collapsed-menu i{
    transform: translateX(5px);
    transition: font-size .2s ease .2s, transform .2s ease .2s;
    vertical-align: middle;
    font-size: 22px;
  }

  .custom-sider-trigger{
    position: fixed;
    bottom: 0;
    text-align: center;
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    color: #6a6c6f;
    background: #fff;
    z-index: 1000;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    border-right: 1px solid #dcdee2;

  }

  .testing-menu-header{
    background-color: #fff;
    border-right: 1px solid #dcdee2;
  }

  .testing-menu-header-title{
    font-size: 20px;
    font-weight: bold;
    color: #334866;
  }


</style>
