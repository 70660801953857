<template>
    <div class="app-body-view-default">
        <Layout>
            <Sider v-if="login" hide-trigger :width="60" ref="globalNavSider" collapsible :collapsed-width="60"
                   v-model="isCollapsed" :style="{background: '#fff'}">
                <Layout :style="{height:windowInnerHeight+'px',background:'#515a6e'}">
                    <Header style="padding:0px;height: 60px;">
                        <Dropdown @on-click="changeSpace" trigger="hover" placement="right" transfer transfer-class-name="global-setting-dropdownmenu">
                           <div class="app-head-logo">
                            <router-link to="/home/worktile"><img src="../../assets/teamvision/global/images/logo.jpg" style="width: 40px;height: 40px; border-radius: 5px;" /></router-link>
                           </div>
                         <template #list>
                         <DropdownMenu >
                         <DropdownItem v-for="space in productSpaces" :selected="userInfo.ViewData.default_space.id===space.id" :name="space.id" :key="space.id">
                             <span style="padding-top: 5px;width: 130px;display: inline-flex">{{ space.Title }}</span>
                             <span style="padding-left: 5px;" v-show="userInfo.ViewData.default_space.id===space.id">
                                 <i class="fa-regular fa-square-check"></i>
                             </span>

                         </DropdownItem>
                         </DropdownMenu>
                         </template>
                      </Dropdown>
                    </Header>
                    <Content style="height: 770px;">
                        <Menu ref="sideMenu" :activeName="activeMenu" mode="vertical" theme="dark" width="60px" :style="{'padding-top':'20px'}" :class="menuitemClasses">
                            <MenuItem class="global-menuitem" name="home" to="/home">
                                <Tooltip content="需求协作" transfer placement="right" theme="light">
                                    <Icon type="ios-home"></Icon>
                                    <div>需求协作</div>
                                </Tooltip>
                            </MenuItem>
                            <MenuItem class="global-menuitem" name="project" to="/project">
                                <Tooltip content="项目" transfer placement="right" theme="light">
                                    <Icon type="ios-bus"></Icon>
                                    <div>项目</div>
                                </Tooltip>
                            </MenuItem>
                            <MenuItem class="global-menuitem" name="ci" to="/ci">
                                <Tooltip content="代码构建" transfer placement="right" theme="light">
                                    <Icon type="ios-planet-outline"/>
                                    <div>代码构建</div>
                                </Tooltip>
                            </MenuItem>
                            <MenuItem class="global-menuitem" name="testing" to="/testing">
                                <Tooltip content="测试管理" transfer placement="right" theme="light">
                                    <Icon type="ios-compass-outline"/>
                                    <div>测试管理</div>
                                </Tooltip>
                            </MenuItem>
                            <MenuItem class="global-menuitem" name="knowledge" to="/knowledge">
                                <Tooltip content="知识管理" transfer placement="right" theme="light">
                                    <Icon type="md-book"/>
                                    <div>知识</div>
                                </Tooltip>
                            </MenuItem>

                            <MenuItem name="system" v-if="dynamicMenu.show" :to="dynamicMenu.path">
                                <Tooltip :content="dynamicMenu.text"  transfer placement="right" theme="light">
                                    <div><i :class="'fa fa-fw '+ dynamicMenu.icon"></i>{{ dynamicMenu.text }}</div>
                                </Tooltip>
                            </MenuItem>
                        </Menu>
                    </Content>
                    <Footer style="background: #515a6e;width: 60px;padding: 24px 0px 24px 0px">
                        <div>
                            <div class="headSettingItem" @mouseenter="showHeadSetting('systemSetting')" name="systemSetting">
                                <div v-if="userInfo.system_permision < 2 || userInfo.is_space_admin">
                                    <Dropdown placement="right" transfer transfer-class-name="global-setting-dropdownmenu">
                                        <Icon color="#f8f8f9" :size="24" type="ios-settings-outline"></Icon>
                                        <template #list>
                                            <div style="padding: 16px;" >
                                                <DropdownMenu>
                                                    <DropdownItem>
                                                        <router-link to="/system/user">
                                                            <span class="dropdown-item-title">
                                                                <i class="fa-solid fa-user"></i>用户管理
                                                            </span>
                                                        </router-link>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <router-link to="/system/setting/runtime">
                                                     <span class="dropdown-item-title">
                                                     <i class="fa-solid fa-gears"></i> 系统设置</span>
                                                        </router-link>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <router-link to="/system/project/issueCategory">
                                                   <span class="dropdown-item-title">

                                                     <i class="fa-solid fa-wrench"></i> 项目设置
                                                    </span>
                                                        </router-link>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <router-link to="/system/product">
                                                     <span class="dropdown-item-title">
                                                       <i class="fa-solid fa-shuttle-space"></i> 空间管理
                                                      </span>
                                                        </router-link>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </div>
                                        </template>
                                    </Dropdown>
                                </div>
                            </div>
                            <div class="headSettingItem" @mouseenter="showHeadSetting('ucCenter')" name="userCenter">
                                <div>
                                    <Dropdown @on-click="userAction" placement="right" transfer  transfer-class-name="global-setting-dropdownmenu">
                                        <Avatar shape="square" :style="{cursor:'pointer',background:'#515a6e',border:'1px solid #f8f8f9'}">
                                            {{ userInfo.name.slice(-2, 3) }}
                                        </Avatar>
                                        <template #list>
                                            <div style="padding: 16px;">
                                                <DropdownMenu>
                                                    <DropdownItem name="user">
                                                        <router-link :to="'/ucenter/'+ userInfo.id +'/profiles'">
                                                        <span class="dropdown-item-title">
                                                             <Icon size="16" type="ios-person-outline"/>
                                                        <span style="">个人中心</span>
                                                        </span>
                                                        </router-link>
                                                    </DropdownItem>
                                                    <DropdownItem name="logout">
                                                        <div >
                                                            <span class="dropdown-item-title" >
                                                                <i class="fa-solid fa-arrow-right-from-bracket"></i>注销</span>
                                                        </div>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </div>
                                        </template>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
<!--                        <div @mouseout="closeHeadSetting(false)" class="footer-setting">-->
<!--                            <Drawer class-name="globalSetting-drawer" :styles="{'background-color': '#F5F7F8'}"-->
<!--                                    :width="240" :mask="false" :transfer="true" placement="left" :height="300"-->
<!--                                    :closable="true" v-model="showHeadSettings">-->
<!--                                <template #header>-->
<!--                                    <div style="line-height: 20px;padding:0px;">-->
<!--                                        {{ headSettingMenuName }}-->
<!--                                    </div>-->
<!--                                </template>-->
<!--                                <CellGroup width="240px" @on-click="clickMenuItem"-->
<!--                                           v-show="headSettingMenuName==='系统设置'">-->
<!--                                    <Cell class="system-setting-cell-item">-->
<!--                                        <router-link to="/system/user">-->
<!--                                            <span>用户管理</span>-->
<!--                                        </router-link>-->
<!--                                    </Cell>-->
<!--                                    <Cell class="system-setting-cell-item">-->
<!--                                        <router-link to="/system/setting/runtime">-->
<!--                            <span>-->
<!--                              系统设置</span>-->
<!--                                        </router-link>-->
<!--                                    </Cell>-->
<!--                                    <Cell class="system-setting-cell-item">-->
<!--                                        <router-link to="/system/project/issueCategory">-->
<!--                            <span>-->
<!--                              <Icon type="ios-bonfire"/>-->
<!--                              项目设置-->
<!--                            </span>-->
<!--                                        </router-link>-->
<!--                                    </Cell>-->
<!--                                    <Cell class="system-setting-cell-item">-->
<!--                                        <router-link to="/system/product">-->
<!--                            <span>-->
<!--                              空间管理-->
<!--                            </span>-->
<!--                                        </router-link>-->
<!--                                    </Cell>-->
<!--                                </CellGroup>-->
<!--                                <CellGroup @on-click="clickMenuItem" v-show="headSettingMenuName==='个人中心'">-->
<!--                                    <Cell class="system-setting-cell-item">-->
<!--                                        <router-link :to="'/ucenter/'+ userInfo.id +'/profiles'">-->
<!--                                            <Icon size="16" type="ios-person-outline"/>-->
<!--                                            <span style="">个人中心</span>-->
<!--                                        </router-link>-->
<!--                                    </Cell>-->
<!--                                    <Cell class="system-setting-cell-item" @click="userAction">-->
<!--                                        <span>注销</span>-->
<!--                                    </Cell>-->
<!--                                </CellGroup>-->
<!--                            </Drawer>-->
<!--                        </div>-->
                    </Footer>
                </Layout>
            </Sider>
            <Content @click="closeHeadSetting(true)" :style="{width:appBodyWidth+'px'}">
                <router-view name="bodyhead"></router-view>
                <router-view name="unloginbodyhead"></router-view>
                <router-view class="app-body-default view animated" name="noHeaderBody" :style="'top:0px;left:60px;height:'+ appBodyHeight+48 +'px;'"></router-view>
                <router-view class="app-body-default view animated" ref="appBody" :style="'top:'+appBodyTop+'px;left:60px;' + 'height:'+ appBodyHeight +'px;'" name="default"></router-view>
                <router-view class="view animated" name="unLoginBody" :style="'height:'+ appBodyHeight +'px;'"></router-view>
            </Content>
        </Layout>
    </div>

</template>

<script>
import BodyHead from './BodyHead.vue'
import HomeProjectWebpart from '../HomeProjectWebpart.vue'
//import {initWebapp,getCookie} from './appBody.js'
// import store from '../../store/index.js'
import {ref, onMounted,nextTick} from 'vue'
import {mapGetters, mapMutations} from 'vuex'

export default {
    name: 'AppBody',
    data() {
        return {
            isCollapsed: true,
            activeMenu: '',
            showHeadSettings: false,
            headSettingMenuName: '',
            productSpaces:[]
        }
    },
    computed: {
        ...mapGetters(['appBodyHeight', 'appBodyTop', 'appBodyWidth', 'login', 'dynamicMenu', 'userInfo','productSpaceList']),
        rotateIcon() {
            return [
                'menu-icon',
                this.isCollapsed ? 'rotate-icon' : ''
            ];
        },
        menuitemClasses() {
            return [
                'menu-item',
                this.isCollapsed ? 'collapsed-menu' : ''
            ]
        },
        windowInnerHeight(){
            return window.innerHeight
        }
    },
    methods: {
        ...mapMutations(['setAppBodyHeight', 'setAppBodyWidth', 'setAppBodyTop', 'setLogin']),
        setBodyHeight() {
            let windowHeight = window.innerHeight-this.appBodyTop
            this.setAppBodyHeight(windowHeight)
        },

        setBodyWidth(siderWidth) {
            let windowWidth = window.innerWidth - siderWidth
            this.setAppBodyWidth(windowWidth)
        },
        collapsedSider() {
            this.$refs.globalNavSider.toggleCollapse()
            this.setBodyWidth(0)
        },
        userAction: function (name) {
            if(name==='logout'){
                this.$axios.delete('/api/common/user/logout').then(response => {
                    this.$cookie.remove('sessionid', {path: '/'})
                    localStorage.clear()
                    this.setLogin(false)
                    sessionStorage.removeItem('userInfo')
                    this.$router.push({path: '/user/logout'})

                }, response => {
                    this.$cookie.remove('sessionid', {path: '/'})
                    localStorage.clear()
                    this.setLogin(false)
                    this.$router.push({path: '/user/logout'})
                })
            }
        },

        changeSpace: function (name) {
            let parameters={default_space:name}
            this.$axios.patch('/api/auth/user/'+ this.userInfo.id+'/extend',parameters).then(response => {
                sessionStorage.removeItem('userInfo')
                window.location = window.location.href
            }, response => {
            })

        },

        clickMenuItem: function () {
            this.showHeadSettings = false
        },

        showHeadSetting: function (menuName) {
            this.showHeadSettings = true
            if (menuName === 'systemSetting') {
                this.headSettingMenuName = '系统设置'
            }
            if (menuName === 'ucCenter') {
                this.headSettingMenuName = '个人中心'
            }
        },
        closeHeadSetting: function (immediately) {
            if (immediately) {
                this.showHeadSettings = false
            } else {

                setTimeout(() => {
                    this.showHeadSettings = false
                }, 10 * 1000)
            }
        }
    },
    components:
        {
            BodyHead,
            HomeProjectWebpart
        },
    created: function () {
        this.productSpaces=this.productSpaceList
    },



    mounted: function () {
        this.setBodyHeight()
        if (this.login) {
            this.setBodyWidth(60)
            window.onresize = () => {
                this.setBodyHeight()
                this.setBodyWidth(60)
            }
            this.setAppBodyTop(75)
        }
        this.$nextTick(() => {
            // this.$refs.sideMenu.updateOpened();
            this.$refs.sideMenu.updateActiveName();
        });
    },

    watch: {
        '$route'(to, from) {
            if (to.name.indexOf('home') === 0) {
                this.activeMenu = 'home'

            }
            if (to.name.indexOf('project') === 0) {
                this.activeMenu = 'project'

            }
            if (to.name.indexOf('ci') === 0) {
                this.activeMenu = 'ci'

            }
            if (to.name.indexOf('env') === 0) {
                this.activeMenu = 'env'

            }
            if (to.name.indexOf('test') === 0) {
                this.activeMenu = 'testing'

            }

            if (to.name.indexOf('system') > -1) {
                this.activeMenu = 'system'

            }

            if (to.name.indexOf('user') > -1) {
                this.activeMenu = 'system'

            }
            nextTick(() => {
                if (this.login) {
                    this.$refs.sideMenu.updateActiveName();
                }

            })
        },
        login: function (value) {
            if (value) {
                this.setBodyWidth(60)
                window.onresize = () => {
                    this.setBodyHeight()
                    this.setBodyWidth(60)
                }
                this.setAppBodyTop(75)
            } else {
                this.setBodyWidth(0)
                window.onresize = () => {
                    this.setBodyHeight()
                    this.setBodyWidth(0)
                }
                this.setAppBodyTop(0)
            }
        },

        appBodyTop: function (value) {
            this.setBodyHeight()
        },
        productSpaceList: function () {
            this.productSpaces=this.productSpaceList
        }

    },
    beforeUpdate: function () {
        //这里不能更改数据，否则会陷入死循环
        console.log('beforeUpdate:重新渲染之前触发')
        console.log('然后vue的虚拟dom机制会重新构建虚拟dom与上一次的虚拟dom树利用diff算法进行对比之后重新渲染')
    },
    updated: function () {
        //这里不能更改数据，否则会陷入死循环
        console.log('updated:数据已经更改完成，dom也重新render完成')
    },
    beforeUnmount: function () {
        console.log('beforeDestory:销毁前执行（$destroy方法被调用的时候就会执行）,一般在这里善后:清除计时器、清除非指令绑定的事件等等...')
        // clearInterval(this.$options.timer)
    },
    unmounted: function () {
        console.log('destroyed:组件的数据绑定、监听...都去掉了,只剩下dom空壳，这里也可以善后')
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less">
@import "../../assets/teamvision/global/less/global";
@import './appBody';
@import './appHead';

.ivu-menu-vertical .ivu-menu-item, .ivu-menu-vertical .ivu-menu-submenu-title {
  padding: 10px !important;
  //position: inherit!important;
  cursor: pointer !important;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}


.ivu-menu {
  display: block;
  color: #6a6c6f;
  font-size: 14px;
  //position: inherit !important;
  z-index: 1;
}

.ivu-menu-item {
  display: block;
  outline: none;
  list-style: none;
  font-size: 14px;
  //position: relative;
  z-index: 1;
  cursor: pointer !important;
  transition: all 0.2s ease-in-out;
}


//.globalSetting-drawer  .ivu-drawer-content {
//  width: 100%;
//  height: 100%;
//  position: absolute;
//  top: 0;
//  bottom: 0;
//  background-color: #fff;
//  border: 0;
//  background-clip: padding-box;
//  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
//  left: 60px;
//}

//.layout{
//  border: 1px solid #d7dde4;
//  background: #f5f7f9;
//  position: relative;
//  border-radius: 4px;
//  overflow: hidden;
//}
//.layout-header-bar{
//  background: #fff;
//  box-shadow: 0 1px 1px rgba(0,0,0,.1);
//}
//.layout-logo-left{
//  width: 90%;
//  height: 30px;
//  background: #5b6270;
//  border-radius: 3px;
//  margin: 15px auto;
//}
//.menu-icon{
//  transition: all .3s;
//}
//.rotate-icon{
//  transform: rotate(-90deg);
//}

.menu-item span{
    display: inline-block;
    overflow: hidden;
    width: 69px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: bottom;
    transition: width .2s ease .2s;
    cursor: pointer;
}
.menu-item i{
    transform: translateX(0px);
    transition: font-size .2s ease, transform .2s ease;
    vertical-align: middle;
    font-size: 16px;
}


.collapsed-menu span {
  width: 60px;
  transition: width .2s ease;
}

.collapsed-menu i {
  //transform: translateX(5px);
  transition: font-size .2s ease .2s, transform .2s ease .2s;
  vertical-align: middle;
  font-size: 24px;
  //margin-left: -10px;
}

.headSettingItem {
  min-height: 60px;
  cursor: pointer;
  padding-top: 16px;
}

.footer-setting .ivu-drawer-inner {
  position: fixed;
  left: 60px;
  width: 240px;
  background-color: #6a6c6f;
}

.system-setting-cell-item span {
  padding: 6px;
  font-weight: bold;
  color: #333333;
}

.global-menuitem {
  font-weight: bold;
}

.globalSetting-drawer:first-child {
  //left: 60px;
}

.global-setting-dropdownmenu {
  left: 65px !important;
  width: 180px !important;

}

.dropdown-item-title{
    color: #6a6c6f;
}
.dropdown-item-title i{
    padding-right: 10px;
    width: 20px;
}


</style>
