<template>
  <div class="app-body-head-default">
    <div class="app-body-header-bar-default">
      <Row>
        <i-col flex="auto">
          <div class="app-body-header-leftbar-default">
            <ul class="app-body-head-menu">
              <router-link to="/home/worktile"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">
                <span><i class="fa fa-fw  fa-bus"></i>工作台</span>
              </router-link>
              <router-link to="/home/summary"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">
                <span><i class="fa fa-fw  fa-bus"></i>看板</span>
              </router-link>
              <router-link :to="{name:'homeRequirement',params:{projectID:0,layout:reqLayout}}"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">
                <span><Icon type="ios-flask"></Icon> 需求</span>
              </router-link>
              <router-link to="/home/task"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">
                <span><i class="fa fa-fw  fa-bus"></i>任务</span>
              </router-link>
              <router-link to="/home/issue"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">
                <span><Icon :size="16" type="ios-bug" /> 问题</span>
              </router-link>
              <!--<router-link to="/home/document"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">-->
                <!--<span><Icon :size="16" type="ios-bug" />文档</span>-->
              <!--</router-link>-->
              <!--<router-link to="/home/qulitytool"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">-->
              <!--<a href="#"><Icon :size="16" type="ios-cube" /> 质量能力</a>-->
              <!--</router-link>-->
              <!--<router-link v-if="homeDynamicMenu.show" :to="homeDynamicMenu.path"  class="app-body-head-menu-item-active app-body-head-menu-item">-->
              <!--<a href="/ci"><Icon :size="16" type="ios-bug" /> {{ homeDynamicMenu.text }}</a>-->
              <!--</router-link>-->
              <!--<router-link to="/home/testcase"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">-->
              <!--<a href="/home/testcase"><Icon type="ios-key" :size="16" />测试</a>-->
              <!--</router-link>-->
              <!--<router-link to="/home/statistics"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">-->
              <!--<a href="/home/statistics"><Icon type="ios-stats" :size="16" /> 统计</a>-->
              <!--</router-link>-->

            </ul>
          </div>
        </i-col>
        <i-col>
          <div class="app-body-header-rightbar-default">
            <span @click="newTask"  v-if="headMenu.newObject">
              <Avatar style="background-color: #37b699;"  class="cursor-hand" icon="md-add" />
            </span>
            <span v-if="routerName==='homeIssue'" style="padding-left: 10px">
             <Divider type="vertical" />
              <Tooltip content="导出" transfer="">
                <a style="color:inherit" :href="'/api/project/0/issue/export?Title__icontains='+searchKeyword+ '&' +issueFilters"><Icon type="md-log-out" :size="20" /></a>
              </Tooltip>
          </span>
            <span v-if="headMenu.storyFilter" style="padding-left: 10px;" class="cursor-hand">
             <Divider type="vertical" />
              <Tooltip content="需求筛选" transfer>
                <span @click="showStoryFilterPanel">
                  <Icon v-if="storyFilterPanelShow" type="ios-funnel-outline" :size="24" />
                  <Icon v-if="!storyFilterPanelShow" type="ios-funnel" :size="24" />
                </span>
              </Tooltip>
          </span>
            <span v-if="headMenu.filterMenu" style="padding-left: 10px;" class="cursor-hand">
             <Divider type="vertical" />
              <Tooltip content="任务筛选" transfer>
                <span @click="showRightPanel">
                  <Icon v-if="!rightSidePanelShow" type="ios-funnel-outline" :size="24" />
                  <Icon v-if="rightSidePanelShow" type="ios-funnel" :size="24" />
                </span>
              </Tooltip>
          </span>
            <span v-if="headMenu.itemViewMode" style="padding-left: 10px;" class="cursor-hand">
             <Divider type="vertical" />
            <Dropdown transfer @on-click="changeViewMode">
             <a href="javascript:void(0)" style="color: inherit;">
                <span>
                  <Icon type="md-apps" :size="24"/>
                </span>
                <Icon type="ios-arrow-down"></Icon>
             </a>
                <template #list>
                    <DropdownMenu>
               <DropdownItem name="board"><Icon type="md-podium" /> 看板</DropdownItem>
               <DropdownItem name="list"><Icon type="ios-paw" />列表</DropdownItem>
             </DropdownMenu>
                </template>
             </Dropdown>
          </span>
          </div>
        </i-col>
      </Row>
  </div>
  </div>
</template>

<script>
  import { mapMutations,mapGetters } from 'vuex'
  import store from '../../store/index.js'


  export default {
    name: 'MyHead',
    data () {
      return {
        reqLayout: {
          projectWidth: 0,
          otherLayout: {
            width: 24,
            featureLayout: {
              width: 3,
              requirementWidth: 21
            }
          }
        }
      }
    },
    computed: {
      ...mapGetters('issue', ['issueFilters','searchKeyword']),
      ...mapGetters('homeglobal',['homeDynamicMenu','headMenu']),
      ...mapGetters('projectglobal',['storyFilterPanelShow','rightSidePanelShow']),
      ...mapGetters(['itemViewMode']),

      routerName: function () {
        return this.$route.name
      }
    },

    beforeRouteEnter (to, from, next) {
      // 在渲染该组件的对应路由被 confirm 前调用
      // 不！能！获取组件实例 `this`
      // 因为当守卫执行前，组件实例还没被创建
      console.log(to)
      next()

    },
    beforeRouteUpdate (to, from, next) {
      // 在当前路由改变，但是该组件被复用时调用
      // 举例来说，对于一个带有动态参数的路径 /foo/:id，在 /foo/1 和 /foo/2 之间跳转的时候，
      // 由于会渲染同样的 Foo 组件，因此组件实例会被复用。而这个钩子就会在这个情况下被调用。
      // 可以访问组件实例 `this`
      console.log(to)
      this.setItemViewMode('board')
      next()
    },
    beforeRouteLeave (to, from, next) {
      // 导航离开该组件的对应路由时调用
      // 可以访问组件实例 `this`
      console.log(to)
      this.setItemViewMode('board')
      next()
    },
    methods: {
    ...mapMutations('projectglobal',['setCreateDialogShow','setRightPanelShow','setStoryFilterPanelShow']),
    ...mapMutations(['setItemViewMode']),
      newTask () {
        this.setCreateDialogShow(true)
      },


      showRightPanel () {
          console.log("show task filter")
          if(this.rightSidePanelShow){
              this.setRightPanelShow(false)
          } else {
              this.setRightPanelShow(true)
          }
      },

      showStoryFilterPanel:function (){
        console.log('show story filter')
      if(this.storyFilterPanelShow){
        this.setStoryFilterPanelShow(false)
      } else {
        this.setStoryFilterPanelShow(true)
      }
      },

      changeViewMode: function (value) {
        this.setItemViewMode(value)
      }
    },
    created: function () {
    },
    mounted: function () {
      this.setRightPanelShow(false)

    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../../components/layout/appBody';
@import '../../assets/teamvision/global/less/global';
</style>
