import { defineAsyncComponent } from 'vue';

import ProjectHead from '../pages/project/Head.vue'
import PortalHead from '../pages/project/PortalHead.vue'
import ProjectTesting from '../pages/project/project-testing/ProjectTesting.vue'
import LeftPanel from "@/pages/project/project-testing/LeftPanel.vue";

const ProjectTestCaseSummary = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-case/ProjectTestCaseSummary.vue')
})

const ProjectTestCaseView = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-case/ProjectTestCaseView.vue')
})


const ProjectTestCasePanel = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-case/ProjectTestCasePanel.vue')
})



const ProjectTestPlanPanel = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-plan/ProjectTestPlanPanel.vue')
})


const ProjectTestPlanSummary = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-plan/ProjectTestPlanSummary.vue')
})
const ProjectTestPlanDetail = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-plan/ProjectTestPlanContent.vue')})

const ProjectTestPlanVersionDetail = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-plan/ProjectTestPlanVersion.vue')})

const ProjectTestPlanExec = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-plan/ProjectTestPlanExecute.vue')})

const ProjectReportSummary = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-report/ProjectTestReport.vue')})

const ProjectReportTemplateSummary = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-report/ProjectTestReportTemplate.vue')})

const ProjectReportDetail = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/test-report/ProjectTestReportDetail.vue')})

const ProjectAutoCaseGroupSummary = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/auto-case/ProjectAutoCaseGroupSummary.vue')})

const ProjectAutoCaseGroupPanel = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/auto-case/ProjectAutoPanel.vue')})

const ProjectAutoCaseGroupDetail = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/auto-case/ProjectAutoCaseGroupdetail.vue')})

const ProjectCaseReviewConclusion = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/case-review/ProjectTestCaseReviewConclusion.vue')})

const ProjectCaseReviewPage = defineAsyncComponent({
  loader: () => import('../pages/project/project-testing/case-review/ProjectTestCaseReviewPage.vue')})


const ProjectTestLeftPanel = defineAsyncComponent({
  loader: ()  => import('../pages/project/project-testing/LeftPanel.vue')})




import EmptyHead from '../components/layout/EmptyBodyHead.vue'
import store from '../store/index'

const projectRouter = [
  {
    name: 'projectRoot',
    path: '/project',
    components: {
      default: function () {
        return import('../pages/project/ProjectList.vue')
      },
      bodyhead: EmptyHead
    },
    meta: ''
  },
  {
    name: 'projectCreate',
    path: '/project/create',
    components: {
      default: function () {
        return import('../pages/project/ProjectCreateStepWizard.vue')
      },
      bodyhead: EmptyHead
    },
    meta: ''
  },
  {
    name: 'projectSummary',
    path: '/project/:projectID/summary',
    components: {
      default: function () {
        return import('../pages/project/project-summary/ProjectSummary.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      console.log(to)
      let headMenu = {
        newObject: false,
        searchBox: false,
        versionBox: false,
        dateRangeBox: false,
        documentNew: false,
        issueExport: false,
        taskViewMode: false,
        taskFilter: false,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },

  {
    name: 'projectRequirement',
    path: '/project/:projectID/requirement',
    components: {
      default: function () {
        return import('../pages/project/project-requirement/ProjectRequirement.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: false,
        searchBox: false,
        versionBox: false,
        dateRangeBox: false,
        documentNew: false,
        itemViewMode: true,
        reqNew: false,
        issueExport: false,
        taskViewMode: false,
        taskFilter: false,
        storyFilter: true,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },

  {
    name: 'projectTask',
    path: '/project/:projectID/task',
    components: {
      default: function () {
        return import('../pages/project/projecttask/ProjectTask.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      console.log(to)
      let headMenu = {
        newObject: true,
        searchBox: false,
        versionBox: true,
        dateRangeBox: false,
        documentNew: false,
        issueExport: false,
        taskViewMode: true,
        taskFilter: true,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },



  {
    name: 'projectTesting',
    path: '/project/:projectID/test/',
    redirect:to => {
      // `to` 是一个路由对象，包含了当前路由的信息，比如参数
      const { params, query } = to;
      return '/project/'+params.projectID+'/test/case/summary'
    },
    components: {default:ProjectTesting,bodyhead: ProjectHead},
    props: {default: true,bodyhead: true},
    children: [
        {
      name: 'projectTestCaseSummary',
      path: 'case/summary',
      components:{
        testingCotent:ProjectTestCaseSummary,
        leftPanel:ProjectTestCasePanel
      },
      props: {testingCotent: true,leftPanel: true},
    },
      {
        name: 'projectTestCaseGroup',
        path: 'case-group/:groupID',
        components: {testingCotent:ProjectTestCaseView,leftPanel:ProjectTestCasePanel},
        props: {testingCotent: true,leftPanel: true},
      },

      {
        name: 'projecttestPlanVersion',
        path: 'plan/versions/:version',
        components: {testingCotent:ProjectTestPlanVersionDetail,leftPanel:ProjectTestPlanPanel},
        props: {testingCotent: true,leftPanel: true},
      },

      {
        name: 'projecttestPlanSummary',
        path: 'plan/summary',
        components: {
          testingCotent:ProjectTestPlanSummary
          ,leftPanel:ProjectTestPlanPanel},
        props: {testingCotent: true,leftPanel: true},
      },
      {
        name: 'projecttestPlanDetail',
        path: 'plan/:planID/detail',
        components: {testingCotent:ProjectTestPlanDetail,leftPanel:ProjectTestPlanPanel},
        props: {testingCotent: true,leftPanel: true},

      },
      {
        name: 'projectTestPlanExec',
        path: 'plan/:planID/tests',
        components: {testingCotent:ProjectTestPlanExec,leftPanel:LeftPanel},
        props: {testingCotent: true,leftPanel: true},
        meta: '',
      },
      {
        name: 'projecttestReport',
        path: 'report',
        components: {testingCotent:ProjectReportSummary},
        props: {testingCotent: true,leftPanel: true},
        meta: '',
      },

      {
        name: 'projecttestReportDetail',
        path: 'report/:reportID',
        components: {testingCotent:ProjectReportDetail},
        props: {testingCotent: true,leftPanel: true},
        meta: '',
      },

      {
        name: 'projecttestReportTemplate',
        path: 'report_template',
        components: {testingCotent:ProjectReportTemplateSummary},
        props: {testingCotent: true,leftPanel: true},
        meta: '',
      },

      {
        name: 'projectTestCaseReviewConclusion',
        path: 'review-conclusion',
        components: {testingCotent:ProjectCaseReviewConclusion},
        props: {testingCotent: true,leftPanel: true},
        meta: '',
      },

      {
        name: 'projectTestCaseReviewConclusionDetail',
        path: 'review-conclusion/:conclusionID',
        components: {testingCotent:ProjectCaseReviewConclusion,leftPanel:ProjectTestLeftPanel},
        props: {testingCotent: true,leftPanel: true},
        meta: '',
      },

      {
        name: 'projectTestCaseReviewPage',
        path: 'review-conclusion/:conclusionID/case-map',
        components: {testingCotent:ProjectCaseReviewPage,leftPanel:ProjectTestLeftPanel},
        props: {testingCotent: true,leftPanel: true},
        meta: '',
      },


      {
        name: 'projectAutoCaseGroup',
        path: 'autogroup/:groupID',
        components: {testingCotent:ProjectAutoCaseGroupDetail,leftPanel:ProjectAutoCaseGroupPanel},
        props: {testingCotent: true,leftPanel: true},
        meta: '',
      },

      {
        name: 'projectAutoCaseSummary',
        path: 'autogroup/summary',
        components: {testingCotent:ProjectAutoCaseGroupSummary,leftPanel:ProjectAutoCaseGroupPanel},
        props: {testingCotent: true,leftPanel: true},
        meta: '',
      }

    ],
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: false,
        searchBox: false,
        versionBox: false,
        dateRangeBox: false,
        documentNew: false,
        issueExport: false,
        taskViewMode: false,
        taskFilter: false,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },


  {
    name: 'projectFortesting',
    path: '/project/:projectID/fortesting',
    components: {
      default: function () {
        return import('../pages/project/project-fortesting/ProjectFortesting.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: true,
        searchBox: false,
        versionBox: true,
        dateRangeBox: false,
        documentNew: false,
        issueExport: false,
        taskViewMode: false,
        taskFilter: true,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },

  {
    name: 'projectFortestingDetail',
    path: '/project/:projectID/fortesting/:fortestingID',
    components: {
      default: function () {
        return import('../pages/project/project-fortesting/ProjectFortesting.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: true,
        searchBox: false,
        versionBox: true,
        dateRangeBox: false,
        documentNew: false,
        issueExport: false,
        taskViewMode: false,
        taskFilter: true,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },





  {
    name: 'projectMindmap',
    path: '/project/:projectID/documents/mindmap/:mindFileID',
    components: {
      default: function () {
        return import('../pages/project/document/mind.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: false,
        searchBox: false,
        versionBox: false,
        dateRangeBox: false,
        documentNew: false,
        issueExport: false,
        taskViewMode: false,
        taskFilter: false,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }},

  {
    name: 'projectMindmapCase',
    path: '/project/:projectID/test/mind_case/:mindFileID',
    components: {
      default: function () {
        return import('../pages/project/project-testing/test-case/xmind/mind.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: false,
        searchBox: false,
        versionBox: false,
        dateRangeBox: false,
        documentNew: false,
        issueExport: false,
        taskViewMode: false,
        taskFilter: false,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },


    {
        name: 'projectMindmapList',
        path: '/project/:projectID/mindmap/',
        components: {
            default: function () {
                return import('../pages/project/project-mindmap/ProjectMindmapFileList.vue')
            },
            bodyhead: ProjectHead
        },
        props: {default: true,bodyhead: true},
        meta: '',
        beforeEnter: (to, from, next) => {
            let headMenu = {
                newObject: true,
                searchBox: false,
                versionBox: true,
                dateRangeBox: false,
                documentNew: false,
                issueExport: false,
                taskViewMode: false,
                taskFilter: false,
                importMindFile: true
            }
            initBodyMenu(headMenu)
            next()
        }
    },


  {
    name: 'projectDocument',
    path: '/project/:projectID/documents/:folderID?',
    components: {
      default: function () {
        return import('../pages/project/document/ProjectDocuments.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: false,
        searchBox: false,
        versionBox: false,
        dateRangeBox: false,
        documentNew: true,
        issueExport: false,
        taskViewMode: false,
        taskFilter: false,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },

  {
    name: 'projectSettings',
    path: '/project/:projectID/settings/:page',
    components: {
      default: function () {
        return import('../pages/project/settings/ProjectSettings.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: true,
        searchBox: false,
        versionBox: false,
        dateRangeBox: false,
        documentNew: false,
        issueExport: false,
        taskViewMode: false,
        taskFilter: false,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },

  {
    name: 'projectStatistics',
    path: '/project/:projectID/statistics/:page?',
    components: {
      default: function () {
        return import('../pages/project/statistics/ProjectStatistics.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: false,
        searchBox: false,
        versionBox: true,
        dateRangeBox: true,
        documentNew: false,
        issueExport: false,
        taskViewMode: false,
        taskFilter: false,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },

  {
    name: 'projectDocumentExcel',
    path: '/project/documents/excel/:documentID/:viewMode?',
    components: {
      default: function () {
        return import('../pages/project/document/Excel.vue')
      },
      bodyhead: EmptyHead
    },
    props: {default: true,bodyhead: true},
    meta: ''
  },

  {
    name: 'projectIssue',
    path: '/project/:projectID/issue/:issueID',
    components: {
      default: function () {
        return import('../pages/project/issue/ProjectIssue.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: true,
        searchBox: false,
        versionBox: false,
        dateRangeBox: false,
        documentNew: false,
        issueExport: true,
        taskViewMode: false,
        taskFilter: false,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },

  {
    name: 'projectIssueStatistics',
    path: '/project/:projectID/issue/statistics',
    components: {
      default: function () {
        return import('../pages/project/statistics/IssueStatistics.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: true,
        searchBox: false,
        versionBox: false,
        dateRangeBox: false,
        documentNew: false,
        issueExport: true,
        taskViewMode: false,
        taskFilter: false,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },

  {
    name: 'projectIssueMobileUpload',
    path: '/project/issue/:issueID/mobile/upload',
    components: {
      default: function () {
        return import('../pages/project/issue/MobileUpload.vue')
      },
      bodyhead: EmptyHead
    },
    props: {default: true,bodyhead: true},
    meta: ''
  },

  {
    name: 'projectFortestingReport',
    path: '/project/:projectID/fortesting/:fortestingID/report/:reportName',
    components: {
      default: function () {
        return import('../pages/project/project-fortesting/report/Report.vue')
      },
      bodyhead: ProjectHead
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: false,
        searchBox: false,
        versionBox: false,
        dateRangeBox: false,
        documentNew: false,
        issueExport: false,
        taskViewMode: false,
        taskFilter: false,
        importMindFile: false
      }
      initBodyMenu(headMenu)
      next()
    }
  },

]

function initBodyMenu (headMenu) {
  store.commit('projectglobal/setHeadMenu',headMenu)
}

export {
  projectRouter
}
