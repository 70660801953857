<template>
  <div></div>
</template>

<script>
  export default {
    name: 'EmptyBodyHead',
    data () {
      return {
        msg: 'Welcome to Your Vue.js App'
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
</style>
