<template>
  <div v-if="appHeadShow && login" id="app-head" class="app-head master-page-header-font">
    <Row>
      <i-col flex="200">
        <div class="app-head-item">
          <div v-if="login" class="app-head-logo">
            <router-link to="/home/summary"><img src="../../assets/teamvision/global/images/logo.jpg" style="width: 40px;height: 40px; border-radius: 5px;" /></router-link>
          </div>
          <div v-if="login" class="app-head-title">
           <span v-if="productSpaces.length>1">
          <Dropdown @on-click="changeSpace">
          <span>
            {{ userInfo.ViewData.default_space.Title }}
            <Icon type="ios-arrow-down"></Icon>
          </span>
              <template #list>
                  <DropdownMenu >
              <DropdownItem v-for="space in productSpaces" :name="space.id" :key="space.id">{{ space.Title }}</DropdownItem>
            </DropdownMenu>
              </template>
          </Dropdown>
        </span>
           <span v-if="productSpaces.length<=1">
          {{ userInfo.ViewData.default_space.Title }}
            </span>
          </div>
        </div>
      </i-col>
      <!--<i-col id="headermenu"  flex="auto" class="app-head-menu app-head-item">-->
        <!--<ul class="list-inline">-->

          <!--<router-link to="/home" tag="li" active-class="app-head-menu-item-active" id="header_menu_home"-->
                       <!--class="app-head-menu-item">-->
            <!--<a href="/home/summary"><Icon type="ios-home"></Icon>协同</a>-->
          <!--</router-link>-->
          <!--<router-link to="/project" tag="li" active-class="app-head-menu-item-active" class="app-head-menu-item">-->
            <!--<a><i class="fa fa-fw  fa-bus"></i>项目</a>-->
          <!--</router-link>-->

          <!--<router-link to="/ci" tag="li" active-class="app-head-menu-item-active" class="app-head-menu-item">-->
            <!--<a href="/ci"><i class="fa fa-fw  fa-building-o"></i>持续交付</a>-->
          <!--</router-link>-->
          <!--&lt;!&ndash;<router-link to="/interface" tag="li" active-class="app-head-menu-item-active" id="header_menu_env"&ndash;&gt;-->
          <!--&lt;!&ndash;class="app-head-menu-item">&ndash;&gt;-->
          <!--&lt;!&ndash;<a href="/interface"><i class="fa fa-fw  fa-bus"></i>API</a>&ndash;&gt;-->
          <!--&lt;!&ndash;</router-link>&ndash;&gt;-->
          <!--<router-link v-if="dynamicMenu.show" :to="dynamicMenu.path" tag="li" class="app-head-menu-item-active app-head-menu-item">-->
            <!--<a href="/ci"><i :class="'fa fa-fw '+ dynamicMenu.icon"></i>{{ dynamicMenu.text }}</a>-->
          <!--</router-link>-->
        <!--</ul>-->
      <!--</i-col>-->
      <!--<i-col v-if="showProject" flex="auto">-->
        <!--<ul class="list-inline">-->
          <!--<li name="header_menu_device" label="device" labelid="3" class="app-head-menu-item cursor-hand"-->
              <!--style="padding-top: 12px;">-->
            <!--<Dropdown trigger="click">-->
               <!--<span style="color:#fff"><Avatar :src="projectInfo.PBAvatar" size="small"/>-->
              <!--<span style="padding-left:5px;">{{ projectInfo.PBTitle }}</span>-->
              <!--<span><Icon :size="24" type="md-arrow-dropdown"/></span>-->
            <!--</span>-->
              <!--<DropdownMenu  style="max-height: 400px;overflow-y: scroll;color:#5e5e5e;">-->
                <!--<DropdownItem v-for="project in myProjects" :key="project.id" :name="project.id">-->
                  <!--<router-link-->
                    <!--:to="{ name: router.name, params: { projectID: project.id,versionID: project.LatestVersion }}"-->
                    <!--tag="li">-->
                    <!--<a href="#" style="text-align: left;height: 30px;display: block;padding-top:5px;color:inherit">-->
                      <!--<span><Avatar :src="project.PBAvatar" size="small"/>-->
                        <!--<span style="padding-left:5px;">{{ project.PBTitle }}</span>-->
                      <!--</span>-->
                    <!--</a>-->
                  <!--</router-link>-->
                <!--</DropdownItem>-->
              <!--</DropdownMenu>-->
            <!--</Dropdown>-->
          <!--</li>-->
        <!--</ul>-->
      <!--</i-col>-->
<!--      <i-col id="headersettings" flex="auto" class="app-head-settings app-head-item">-->
<!--        <div v-if="login">-->
<!--&lt;!&ndash;          <router-link style="color:#333" :to="'/ucenter/'+ userInfo.id +'/notification'">&ndash;&gt;-->
<!--&lt;!&ndash;            <Badge :count="0" style="margin-right: 20px;" :offset="[5,-5]">&ndash;&gt;-->
<!--&lt;!&ndash;              <Icon :size="24" type="ios-notifications" />&ndash;&gt;-->
<!--&lt;!&ndash;            </Badge>&ndash;&gt;-->
<!--&lt;!&ndash;          </router-link>&ndash;&gt;-->
<!--          <Dropdown v-if="userInfo.system_permision < 2 || userInfo.is_space_admin">-->
<!--            <Icon :size="24" type="ios-settings"></Icon>-->
<!--            <DropdownMenu >-->
<!--              <DropdownItem>-->
<!--                <router-link to="/system/user" tag="li">-->
<!--                  <a style="color:inherit" href="#">-->
<!--                    <i class="fa fa-user fa-fw"></i> 用户</a>-->
<!--                </router-link>-->
<!--              </DropdownItem>-->

<!--              <DropdownItem v-if="userInfo.system_permision < 2">-->
<!--                <router-link to="/system/setting/runtime" tag="li">-->
<!--                  <a style="color:inherit" href="#">-->
<!--                    <i class="fa fa-cogs fa-fw"></i> 系统</a>-->
<!--                </router-link>-->
<!--              </DropdownItem>-->
<!--              <DropdownItem v-if="userInfo.system_permision < 2">-->
<!--                <router-link to="/system/project/issueCategory" tag="li" >-->
<!--                  <a style="color:inherit" href="" >-->
<!--                    <Icon type="ios-bonfire" />-->
<!--                    项目-->
<!--                  </a>-->
<!--                </router-link>-->
<!--              </DropdownItem>-->
<!--              <DropdownItem v-if="userInfo.system_permision < 2">-->
<!--                <router-link to="/system/product" tag="li" >-->
<!--                  <a style="color:inherit" href="" >-->
<!--                    <i class="fa fa-fw  fa-bus"></i>-->
<!--                    空间-->
<!--                  </a>-->
<!--                </router-link>-->
<!--              </DropdownItem>-->



<!--            </DropdownMenu>-->
<!--          </Dropdown>-->
<!--          <Dropdown  @on-click="userAction" style="margin-left: 20px" placement="bottom-end">-->
<!--            <Avatar :style="{background:'#515a6e',cursor:'pointer'}">{{ userInfo.name.slice(-2,3) }}</Avatar>-->
<!--            <DropdownMenu >-->
<!--              <DropdownItem name="ucenter">-->
<!--                <router-link style="color:#333" :to="'/ucenter/'+ userInfo.id +'/profiles'"><i-->
<!--                  class="fa fa-home fa-fw pull-left"></i>个人中心-->
<!--                </router-link>-->
<!--              </DropdownItem>-->
<!--              <DropdownItem  name="logout" divided>-->
<!--                <span style="color:#333">-->
<!--                <i class="fa fa-sign-out fa-fw pull-left"></i>注销</span></DropdownItem>-->
<!--            </DropdownMenu>-->
<!--          </Dropdown>-->
<!--        </div>-->
<!--        <div v-if="!login">-->
<!--          <Button  shape="circle" to="/user/login" style="width: 100px;color:black;">登录</Button>-->
<!--        </div>-->
<!--      </i-col>-->
    </Row>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  components: {},
  name: 'AppHead',
  data () {
    return {
      systemPermission: 99,
      productSpaces: [],
      selectSpace: {},
      userID: 0,
      showHeadProject: false,
      projectID: 0,
      projectInfo: null,
      myProjects: [],
      router: {name:''}
    }
  },
  computed: {
    ...mapGetters(['appHeadShow', 'userInfo', 'dynamicMenu', 'login','productSpace','productSpaceList']),

    showProject: function () {
      return this.showHeadProject
    }
  },

  methods: {
    ...mapMutations(['setUserInfo','setLogin']),

    loadProjectInfo: function () {
      if (this.projectID !== 0) {
        this.$axios.get('/api/project/' + this.projectID + '/detail?extinfo=0').then(response => {
          this.projectInfo = response.data.result
        }, response => {
        })

      }
    },

    changeSpace: function (name) {
      let parameters={default_space:name}
      this.$axios.patch('/api/auth/user/'+ this.userInfo.id+'/extend',parameters).then(response => {
        sessionStorage.removeItem('userInfo')
        window.location = window.location.href
      }, response => {
      })

    },

    loadMyProject: function () {
      this.$axios.get('/api/project/list?extinfo=0&my=1').then(response => {
        this.myProjects = response.data.result
      }, response => {
      })
    },

    getSpaceList: function () {
      this.$axios.get('/api/project/product_spaces').then(response => {
        this.productSpaces = response.data.result
      }, response => {
        // error callback
      })
    },

    userAction: function (value) {
      if (value === 'logout') {
        this.$axios.delete('/api/common/user/logout').then(response => {
          this.$cookie.remove('sessionid', {path: '/'})
          localStorage.clear()
          this.setLogin(false)
          sessionStorage.removeItem('userInfo')
          this.$router.push({path: '/user/logout'})

        }, response => {
          this.$cookie.remove('sessionid', {path: '/'})
          localStorage.clear()
          this.setLogin(false)
          this.$router.push({path: '/user/logout'})
        })
        this.productSpaces=[]
      }
    }
  },

  created: function () {
    if (this.login) {
      this.loadProjectInfo()
      this.loadMyProject()
      this.productSpaces=this.productSpaceList
    }
  },
  mounted: function () {
  },
  watch: {
    '$route' (to, from) {
      if (to.name !== 'projectRoot' && to.name.indexOf('project') > -1) {
        this.showHeadProject = true
        this.projectID = to.params.projectID
        this.router = to
      } else {
        this.showHeadProject = false
        this.projectID = 0
      }
    },
    projectID: function () {
      this.loadProjectInfo()
    },

    productSpaceList: function () {
      this.productSpaces=this.productSpaceList
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  @import "appHead";
</style>
