import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex'
import task from './project/task'
import issue from './project/issue'
import fortesting from './project/fortesting'
import project from './project/project'
import projectglobal from './project/projectglobal'
import citask from './ci/citask'
import citaskflow from './ci/citaskflow'
import mockapi from './interface/mockapi'
import document from './project/document'
import systemglobal from './system/systemglobal'
import ucenterglobal from './ucenter/ucenterglobal'
import user from './system/user'
import ciglobal from './ci/ciglobal'
import homeglobal from './home/homeglobal'
import testcase from './project/testcase'
import requirement from "@/store/project/requirement";


const state = {
  appBodyHeight: 0,
  appBodyWidth: '100%',
  inProject: false,
  itemViewMode: 'list',
  userInfo: {},
  appHeadShow: true,
  appBodyTop: '0',
  dynamicMenu: {text: '',path: '',icon: '',parameters: {},show: false},
  login: false,
  productSpace: 0,
  productSpaceList: [],
  spaceUserList: [],
  spaceSimpleProjectList: []
}
const getters = {
  appBodyHeight (state) {
    return state.appBodyHeight
  },
  inProject (state) {
    return state.inProject
  },

  itemViewMode (state) {
    return state.itemViewMode
  },

  userInfo (state) {
    return state.userInfo
  },
  appHeadShow (state) {
    return state.appHeadShow
  },

  appBodyTop (state) {
    return state.appBodyTop
  },

  dynamicMenu (state) {
    return state.dynamicMenu
  },

  login (state) {
    return state.login
  },

  appBodyWidth (state) {
    return state.appBodyWidth
  },
  productSpace (state) {
    return state.productSpace
  },
  productSpaceList (state) {
    return state.productSpaceList
  },
  spaceUserList (state) {
    return state.spaceUserList
  },
  spaceSimpleProjectList (state) {
    return state.spaceSimpleProjectList
  }
}
const mutations = {
  setAppBodyHeight (state, height) {
    state.appBodyHeight = height
  },
  setInProject (state, inProject) {
    state.inProject = inProject
  },
  setUserInfo (state, userInfo) {
    state.userInfo = userInfo
  },
  setAppHeadShow (state,appHeadShow) {
    state.appHeadShow = appHeadShow
  },

  setAppBodyTop (state,bodyTop) {
    state.appBodyTop = bodyTop
  },

  setItemViewMode (state,mode) {
    state.itemViewMode = mode
  },

  setDynamicMenu (state,dynamicMenu) {
    state.dynamicMenu = dynamicMenu
  },

  setLogin (state,isLogin) {
    state.login = isLogin
  },

  setAppBodyWidth (state,width) {
    state.appBodyWidth = width
  },

  setProductSpace (state,space) {
    return state.productSpace = space
  },
  setProductSpaceList (state,spaceList) {
    state.productSpaceList = spaceList
  },
  setSpaceUserList (state,spaceUserList) {
    state.spaceUserList = spaceUserList
  },

  setSpaceSimpleProjectList (state,spaceSimpleProjectList) {
    state.spaceSimpleProjectList = spaceSimpleProjectList
  },
}
const actions = {
  setAppBodyHeight (context, height) {
    context.commit('setAppBodyHeight', height)
  },

  setInProjectAction (context, height) {
    context.commit('setInProject', height)
  }
}

const globalModules={
  namespaced:true,
  state,
  getters,
  mutations,
  actions
}
const modules = {
  task,
  citask,
  project,
  citaskflow,
  mockapi,
  fortesting,
  projectglobal,
  issue,
  document,
  systemglobal,
  ucenterglobal,
  user,
  ciglobal,
  homeglobal,
  testcase,
  requirement
}


const store = createStore({
  // strict: true,
  actions,
  getters,
  state,
  mutations,
  modules
}
)

export default store
