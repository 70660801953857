
import store from '../store/index'

const systemAdminRouter = [
  {
    name: 'systemAdminUser',
    path: '/system/user',
    components: {
      default: function () {
        return import('../pages/system-admin/user/UserAdmin.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(true)
      next()
    },

    meta: ''
  },

  {
    name: 'systemSetting',
    path: '/system/setting/',
    redirect: (to) => {
      // 函数接收目标路由对象作为参数
      // 可以基于该对象进行逻辑处理，然后返回一个重定向的路径
      const { hash, params, query } = to;
      return { path: '/system/setting/runtime' };
      // return { path: '/another-path/${params.id}' };
      // if (query.toRedirect) {
      //   return { path: `/another-path/${params.id}` };
      // }
      // // 如果不需要重定向，则返回 false 或 undefined
      // return; // 或者返回 false，取消重定向
    }
  },

  {
    name: 'systemRuntimeSetting',
    path: '/system/setting/runtime',
    components: {
      default: function () {
        return import('../pages/system-admin/setting/SystemSeting.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(false)
      next()
    },
    meta: ''
  },

  {
    name: 'systemEvent',
    path: '/system/setting/systemevent',
    components: {
      default: function () {
        return import('../pages/system-admin/setting/SystemSeting.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(true)
      next()
    },
    meta: ''
  },

  {
    name: 'systemWebHook',
    path: '/system/setting/webhook',
    components: {
      default: function () {
        return import('../pages/system-admin/setting/SystemSeting.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(true)
      next()
    },
    meta: ''
  },

  {
    name: 'systemTask',
    path: '/system/setting/systemTask',
    components: {
      default: function () {
        return import('../pages/system-admin/setting/SystemSeting.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(true)
      next()
    },
    meta: ''
  },

  {
    name: 'systemProduct',
    path: '/system/product',
    components: {
      default: function () {
        return import('../pages/system-admin/product/Product.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(true)
      next()
    },

    meta: ''
  },

  {
    name: 'systemProjectSetting',
    path: '/system/project/',
    redirect: (to) => {
      // 函数接收目标路由对象作为参数
      // 可以基于该对象进行逻辑处理，然后返回一个重定向的路径
      const { hash, params, query } = to;
      return '/system/project/issueCategory'
    },
  },

  {
    name: 'systemProjectIssueCategory',
    path: '/system/project/issueCategory',
    components: {
      default: function () {
        return import('../pages/system-admin/project/ProjectConfigAdmin.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(false)
      next()
    },

    meta: ''
  },
  {
    name: 'systemProjectWorkFlow',
    path: '/system/project/workflow',
    components: {
      default: function () {
        return import('../pages/system-admin/project/ProjectConfigAdmin.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(true)
      next()
    },

    meta: ''
  },

  {
    name: 'systemProjectWorkFlowDiagramEdit',
    path: '/system/project/workflow/:flowID/diagram',
    components: {
      default: function () {
        return import('../pages/system-admin/project/ProjectConfigAdmin.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(true)
      next()
    },
    props: {default: true,bodyhead: true},
    meta: ''
  },

  {
    name: 'systemProjectIssuePhrase',
    path: '/system/project/issuePhrase',
    components: {
      default: function () {
        return import('../pages/system-admin/project/ProjectConfigAdmin.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(false)
      next()
    },

    meta: ''
  },

  {
    name: 'systemProjectTeam',
    path: '/system/user/team',
    components: {
      default: function () {
        return import('../pages/system-admin/user/UserAdmin.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu(false)
      next()
    },

    meta: ''
  },




  {
    name: 'systemAdminUserGroup',
    path: '/system/usergroup',
    components: {
      default: function () {
        return import('../pages/system-admin/user/UserAdmin.vue')
      },
      bodyhead: function () {
        return import('../pages/system-admin/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu()
      next()
    },

    meta: ''
  }
]

function initBodyMenu (newObject) {
  let headMenu = {newObject: newObject}
  store.commit('systemglobal/setHeadMenu',headMenu)
}

function initGlobalMenu (to) {
  let headMenu = {text: '系统管理',path: to.path,icon: 'fa-cogs',parameters: {},show: true}
  store.commit('setDynamicMenu',headMenu)
}

export {
  systemAdminRouter
}
