import Vue from 'vue'
import Vuex from 'vuex'


const state = {
  ciDynamicMenu: { text: '', path: '', icon: '', parameters: {},show: false},
  ciHeadToolShow: {newObject: false,setting: true,taskSearchBox: true},
  ciNewObject: false
}
const getters = {
  ciDynamicMenu (state) {
    return state.ciDynamicMenu
  },

  ciHeadToolShow (state) {
    return state.ciHeadToolShow
  },

  ciNewObject (state) {
    return state.ciNewObject
  }
}
const mutations = {
  setCIDynamicMenu (state,dynamicMenu) {
    state.ciDynamicMenu = dynamicMenu
  },

  setCIHeadToolShow (state,headTool) {
    state.ciHeadToolShow = headTool
  },

  setCINewObject (state,newObject) {
    state.ciNewObject = newObject
  }
}
const actions = {

}
const modules = {}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
  modules
}
