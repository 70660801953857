import Vue from 'vue'
import Vuex from 'vuex'


const state = {
  createDialogShow: false,
  caseSelectDialogShow: false,
  reviewConclusionDialogShow: false,
  viewDialogShow: false,
  importXmindFile: false,
  projectVersion: 0,
  project: 0,
  objectChange: false,
  rightSidePanelShow: false,
  storyFilterPanelShow:false,
  taskViewMode: 'board',
  createDocumentType: 0,
  createReqType: 0,
  projectRole: 1,
  dateRange: '',
  testMenuCollapse: false,
  testPlanFiled: {version:0,planID:0},
  headMenu: {
    newObject: true,
    searchBox: false,
    versionBox: false,
    dateRangeBox: false,
    documentNew: false,
    reqNew: false,
    issueExport: false,
    taskViewMode: false,
    taskFilter: false,
    storyFilter: false,
    importMindFile: false
  },
}
const getters = {
  createDialogShow (state) {
    return state.createDialogShow
  },
  caseSelectDialogShow (state) {
    return state.caseSelectDialogShow
  },

  reviewConclusionDialogShow (state) {
    return state.reviewConclusionDialogShow
  },
  viewDialogShow (state) {
    return state.viewDialogShow
  },
  projectVersion (state) {
    return state.projectVersion
  },
  project (state) {
    return state.project
  },
  objectChange (state) {
    return state.objectChange
  },
  rightSidePanelShow (state) {
    return state.rightSidePanelShow
  },

  storyFilterPanelShow(state){
    return state.storyFilterPanelShow
  },

  taskViewMode (state) {
    return state.taskViewMode
  },

  createDocumentType (state) {
    return state.createDocumentType
  },

  createReqType (state) {
    return state.createReqType
  },

  projectRole (state) {
    return state.projectRole
  },

  headMenu (state) {
    return state.headMenu
  },

  dateRange (state) {
    return state.dateRange
  },

  importXmindFile (state) {
    return state.importXmindFile
  },

  testMenuCollapse(state){
    return state.testMenuCollapse
  },

  testPlanFiled(state){
    return state.testPlanFiled
  }

}
const mutations = {
  setCreateDialogShow (state, isShow) {
    state.createDialogShow = isShow
  },
  setCaseSelectDialogShow (state, isShow) {
    state.caseSelectDialogShow = isShow
  },
  setViewDialogShow (state, isShow) {
    state.viewDialogShow = isShow
  },

  setReviewConclusionDialogShow (state, isShow) {
    state.reviewConclusionDialogShow = isShow
  },
  setProjectVersion (state, versionID) {
    state.projectVersion = versionID
  },

  setProject (state, project) {
    state.project = project
  },

  setObjectChange (state, isChange) {
    state.objectChange = isChange
  },

  setRightPanelShow (state, isShow) {
    state.rightSidePanelShow = isShow
  },

  setStoryFilterPanelShow(state,isShow){
    state.storyFilterPanelShow = isShow
  },

  setTaskViewMode (state,mode) {
    state.taskViewMode = mode
  },

  setCreateDocumentType (state,type) {
    state.createDocumentType = type
  },

  setCreateReqType (state,type) {
    state.createReqType = type
  },

  setProjectRole (state,role) {
    state.projectRole = role
  },

  setHeadMenu (state,menu) {
    state.headMenu = menu
  },

  setDateRange (state,dateRange) {
    state.dateRange = dateRange
  },
  setImportXmindFile (state,importFile) {
    state.importXmindFile = importFile
  },

  setTestMenuCollapse (state,collapse) {
    state.importXmindFile = collapse
  },

  setTestPlanFiled(state,filed){
    state.testPlanFiled = filed
  }
}
const actions = {
  setCreateDialogShowAction (context, isShow) {
    context.commit('setCreateDialogShow', isShow)
  },
  setViewDialogShowAction (context, isShow) {
    context.commit('setViewDialogShow', isShow)
  },
  setProjectVersionAction (context, versionID) {
    context.commit('setProjectVersion', versionID)
  },
  setObjectChangeAction (context, isChanged) {
    context.commit('setObjectChange', isChanged)
  }
}
const modules = {}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
  modules
}
