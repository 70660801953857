import store from '../store/index'


const testingRouter = [
  {
    name: 'testingRoot',
    path: '/testing',
    redirect: '/test/worktile'
  },

  {
    name: 'testFortesting',
    path: '/test/fortesting',
    components: {
      default: function () {
        return import('../pages/project/project-fortesting/ProjectFortesting.vue')
      },
      bodyhead: function () {
        return import('../pages/testing/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: true,
        itemViewMode: true,
        filterMenu: true,
        exportIssue: false
      }
      hideDynamicHomeMenu()
      initBodyMenu(headMenu)
      next()
    }
  },
  {
    name: 'homeTestingPlan',
    path: '/home/test/test-plan',
    components: {
      default: function () {
        return import('../pages/project/project-testing/ProjectTesting.vue')
      },
      bodyhead: function () {
        return import('../pages/home/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: false,
        itemViewMode: false,
        filterMenu: false,
        exportIssue: false
      }
      hideDynamicHomeMenu()
      initBodyMenu(headMenu)
      to.params.projectID = 0
      to.params.action = 'test-plan'
      console.log(to)

      next()
    }
  },

  {
    name: 'testPlanSchedule',
    path: '/test/testplan',
    components: {
      default: function () {
        return import('../pages/testing/TestPlanSchedule.vue')
      },
      bodyhead: function () {
        return import('../pages/testing/Head.vue')
      }
    },
    props: {bodyhead: true, default: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: false,
        itemViewMode: false,
        filterMenu: false,
        exportIssue: false
      }
      hideDynamicHomeMenu()
      initBodyMenu(headMenu)
      to.params.projectID = 0
      console.log(to)

      next()
    }
  },


  {
    name: 'testIssue',
    path: '/test/issue',
    components: {
      default: function () {
        return import('../pages/project/issue/ProjectIssue.vue')
      },
      bodyhead: function () {
        return import('../pages/testing/Head.vue')
      }
    },
    props: {default: true,bodyhead: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: true,
        itemViewMode: false,
        filterMenu: false,
        exportIssue: true
      }
      initBodyMenu(headMenu)
      initDynamicHomeMenu(to, '问题',true)
      next()
    }
  },
  {
    name: 'testWorktile',
    path: '/test/worktile',
    components: {
      default: function () {
        return import('../pages/testing/MyTestItem.vue')
      },
      bodyhead: function () {
        return import('../pages/testing/Head.vue')
      }
    },
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: false,
        itemViewMode: false,
        filterMenu: false,
        exportIssue: false
      }
      initBodyMenu(headMenu)
      hideDynamicHomeMenu()
      next()
    }
  },
]


function initDynamicHomeMenu (to,menuText,isshow) {
  let headMenu = {text: menuText,path: to.path,icon: 'fa-cogs',parameters: {},show: isshow}
  store.commit('homeglobal/setHomeDynamicMenu',headMenu)
}

function hideDynamicHomeMenu () {
  let headMenu = {text: '',path: '',icon: 'fa-cogs',parameters: {},show:false}
  store.commit('homeglobal/setHomeDynamicMenu',headMenu)
}

function initBodyMenu (headMenu) {
  store.commit('homeglobal/setHeadMenu',headMenu)
}

export {
  testingRouter
}
