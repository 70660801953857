<template>
  <div class="app-body-head-default">
  <div class="app-body-header-bar-default">
    <Row>
      <i-col :lg="18">
        <div class="app-body-header-leftbar-default" style="padding-left: 0px!important;">

          <ul class="app-body-head-menu">
            <li>
              <div flex="auto">
                <ul class="list-inline">
                  <li  class="app-body-head-menu-item cursor-hand" style="padding-top: 12px;width: 200px;">
                    <Dropdown trigger="click" transfer>
                          <span style="color: #0c0c0c;"><Avatar :src="projectInfo.PBAvatar" size="small"/>
                          <span style="padding-left:5px; color:#0C0C0C;">{{ projectInfo.PBTitle }}</span>
                          <span><Icon :size="24" type="md-arrow-dropdown"/></span>
                          </span>
                      <template #list>
                          <DropdownMenu  style="max-height: 400px;overflow-y: scroll;">
                              <DropdownItem v-for="project in myProjects" :key="project.id" :name="project.id">
                                  <router-link :to="{ name: router.name, params: { projectID: project.id,versionID: project.LastestVersion }}">
                                      <span style="text-align: left;height: 30px;display: block;padding-top:5px;color:#6a6c6f">
                                           <span>     <Avatar :src="project.PBAvatar" size="small"/>
                                                    <span style="padding-left:5px;width: 150px;">{{ project.PBTitle }}</span>
                                           </span>
                                      </span>
                                  </router-link>
                              </DropdownItem>
                          </DropdownMenu>
                      </template>
                    </Dropdown>
                  </li>
                </ul>
              </div>
            </li>
            <router-link :to="'/project/'+ projectID +'/requirement'" :exact="false"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">
              <span ><Icon type="ios-flask"></Icon> 需求</span>
            </router-link>
            <router-link :to="'/project/'+projectID+'/task'"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">
              <span ><Icon type="md-list" /> 任务</span>
            </router-link>
            <!--<router-link :to="'/project/'+projectID+'/mindmap'"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">-->
              <!--<a :href="'/project/'+projectID+'/mindmap'" >-->
                <!--<span><Icon type="ios-key" />思维导图</span>-->
              <!--</a>-->
            <!--</router-link>-->
            <router-link :to="'/project/'+projectID+'/fortesting'"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">
              <span>
                <Icon type="ios-bus" /> 提测
              </span>
            </router-link>

            <router-link :to="'/project/'+projectID+'/test/'" :exact="false"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">
              <span >
                <Icon type="ios-bus" /> 测试
              </span>
            </router-link>
            <router-link :to="'/project/'+ projectID +'/issue/all'" :exact="false"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">
              <span><i class="fa fa-fw  fa-bug"></i> 问题</span>
            </router-link>

<!--            <router-link :to="'/project/'+ projectID +'/statistics'" :exact="false"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">-->
<!--              <span><Icon type="ios-stats"/> 统计</span>-->
<!--            </router-link>-->
            <!--<router-link :to="'/project/'+projectID+'/documents'"  active-class="app-body-head-menu-item-active" class="app-body-head-menu-item">-->
              <!--<a :href="'/project/'+projectID+'/documents'"><Icon type="md-albums" /> 文件</a>-->
            <!--</router-link>-->

            <router-link v-if="routerName === 'projectSettings'" :to="{ name: routerName, params: { projectID: projectID,page: settingsViewName }}"  class="app-body-head-menu-item-active app-body-head-menu-item">
              <span >
                <Icon :size="24" type="ios-settings" />
                {{ settingsName }}
              </span>
            </router-link>
          </ul>

        </div>
      </i-col>
<!--      <i-col :lg="2" >-->
<!--        <div class="pull-left" style="padding-top: 20px;">-->
<!--          <span v-if="headMenu.versionBox">-->
<!--            <Select v-model="lastestVersion"  @on-change="onVersionChange" :transfer="true" size="small" style="width:200px"  :filterable="true">-->
<!--              <Option v-for="item in versionList" :value="item.id" :key="item.id" :label="item.VersionLabel">-->
<!--                {{ item.VersionLabel }}-->
<!--              </Option>-->
<!--            </Select>-->
<!--          </span>-->
<!--        </div>-->
<!--      </i-col>-->
<!--      <i-col :lg="2">-->
<!--        <div class="pull-left" style="padding-top: 20px;">-->
<!--          <span v-if="headMenu.dateRangeBox">-->
<!--            <DatePicker v-model="statisticsDateRange" transfer type="daterange"  @on-change="selectDateRange" size="small" :options="dateRangeOption" placement="bottom-end" placeholder="选择日期" style="width: 200px"></DatePicker>-->
<!--          </span>-->
<!--        </div>-->
<!--      </i-col>-->
      <i-col :lg="6" >
        <div style="text-align: right;padding-right: 50px;" class="app-body-header-rightbar-default pull-right">
          <span @click="newObject" v-if="headMenu.newObject">
              <Avatar style="background-color: #37b699;"  class="cursor-hand" icon="md-add" />
          </span>
          <span @click="importMindFile" v-if="headMenu.importMindFile">
              <tooltip content="导入Xmind文件" transfer>
                <Avatar style="background-color: #6a6c6f;" size="small" class="cursor-hand" icon="md-log-in" />
              </tooltip>
            <Divider type="vertical" />
          </span>
          <span v-if="headMenu.reqNew">
             <Dropdown transfer @on-click="createReqirement">
               <span>
                <Avatar style="background-color: #37b699;"  class="cursor-hand" icon="md-add" />
                <Icon type="ios-arrow-down"></Icon>
               </span>
                 <template #list>
                     <DropdownMenu >
                  <DropdownItem name="1"><Icon type="ios-flask"></Icon> 需求</DropdownItem>
                  <DropdownItem name="2"><Icon type="ios-bus" /> 提测</DropdownItem>
                         <!--<DropdownItem name="3"><Icon type="ios-cloud-upload" />模块</DropdownItem>-->
               </DropdownMenu>
                 </template>>
             </Dropdown>
            <Divider type="vertical" />
          </span>
          <span v-if="headMenu.documentNew">
             <Dropdown transfer @on-click="createDocument">
               <span>
                <Avatar style="background-color: #32be77;"  class="cursor-hand" icon="md-add" />
                <Icon type="ios-arrow-down"></Icon>
               </span>
                 <template #list>
                     <DropdownMenu >
                  <DropdownItem name="1"><Icon type="ios-folder" /> 文件夹</DropdownItem>
                  <DropdownItem name="2"><i class="fa fa-file-excel-o fa-fw"></i> Excel</DropdownItem>
                  <DropdownItem name="4"><Icon type="md-map" /> 思维导图</DropdownItem>
                  <DropdownItem name="3"><Icon type="ios-cloud-upload" />上传文件</DropdownItem>
               </DropdownMenu>
                 </template>>
             </Dropdown>
          </span>
          <span v-if="headMenu.issueExport" style="padding-left: 10px">
             <Divider type="vertical" />
              <Tooltip content="导出" transfer>
                <span class="cursor-hand"  @click="exportIssueExcel"  ><Icon  type="md-log-out" :size="20" /></span>
              </Tooltip>
            <Divider type="vertical" />
              <Tooltip content="统计" transfer>
                <router-link :to="'/project/'+projectID+'/issue/statistics'"><Icon color="#6a6c6f"  type="ios-stats" :size="20" /></router-link>
              </Tooltip>
          </span>
          <span v-if="headMenu.taskViewMode" style="padding-left: 10px;" class="cursor-hand">
            <Divider type="vertical" />
            <Dropdown transfer @on-click="taskViewSwitch">
             <a href="javascript:void(0)" style="color: inherit;">
                <span>
                  <Icon type="md-apps" :size="24"/>
                </span>
                <Icon type="ios-arrow-down"></Icon>
             </a>
                <template #list>
                    <DropdownMenu >
               <DropdownItem name="board"><Icon type="md-podium" /> 看板</DropdownItem>
<!--               <DropdownItem name="gannt"><Icon type="ios-paw" /> 甘特图</DropdownItem>-->
               <DropdownItem name="list"><Icon type="ios-paw" /> 列表</DropdownItem>
             </DropdownMenu>
                </template>
             </Dropdown>
          </span>
          <span v-if="headMenu.taskFilter===true">
              <Divider type="vertical" />
                <Tooltip content="筛选" transfer v-if="headMenu.taskFilter===true">
                <span @click="showRightPanel">
                  <Icon v-if="!rightSidePanelShow" type="ios-funnel-outline" :size="24" />
                  <Icon v-if="rightSidePanelShow" type="ios-funnel" :size="24" />
                </span>
              </Tooltip>
          </span>
          <span v-if="headMenu.storyFilter===true">
              <Divider type="vertical" />
                <Tooltip content="需求筛选" transfer>
                <span @click="showStoryFilterPanel">
                  <Icon v-if="storyFilterPanelShow" type="ios-funnel-outline" :size="24" />
                  <Icon v-if="!storyFilterPanelShow" type="ios-funnel" :size="24" />
                </span>
              </Tooltip>
          </span>
          <span v-if="headMenu.itemViewMode" style="padding-left: 10px;" class="cursor-hand">
             <Divider type="vertical" />
            <Dropdown transfer @on-click="changeViewMode">
             <a href="javascript:void(0)" style="color: inherit;">
                <span>
                  <Icon type="md-apps" :size="24"/>
                </span>
                <Icon type="ios-arrow-down"></Icon>
             </a>
                <template #list>
                     <DropdownMenu >
               <DropdownItem name="board"><Icon type="md-podium" /> 看板</DropdownItem>
               <DropdownItem name="list"><Icon type="ios-paw" /> 列表</DropdownItem>
             </DropdownMenu>
                </template>
             </Dropdown>
          </span>
          <span  class="cursor-hand">
            <Divider type="vertical" />
            <Dropdown transfer @on-click="settingsViewSwitch">
             <a href="javascript:void(0)" style="color: inherit;">
                <span>
                  <Icon :size="24" type="ios-settings" />
                </span>
                <Icon type="ios-arrow-down"></Icon>
             </a>
                 <template #list>
                      <DropdownMenu >
                 <DropdownItem name="1:项目信息:info"><Icon type="md-podium" />
                   <router-link :to="'/project/'+projectID+'/settings/info'" style="color: #6a6c6f;padding-left: 5px;" >项目信息</router-link>
                 </DropdownItem>
                 <DropdownItem name="2:版本规划:version"><Icon type="ios-paw" />
                   <router-link :to="'/project/'+projectID+'/settings/version'" style="color: #6a6c6f;padding-left: 5px;"  >版本规划</router-link>
                 </DropdownItem>
                 <DropdownItem name="3:成员:member"><Icon type="md-people" />
                   <router-link :to="'/project/'+projectID+'/settings/member'" style="color: #6a6c6f;padding-left: 5px;" >成员</router-link>
                 </DropdownItem>
                 <DropdownItem name="4:模块:module"><Icon type="ios-cube" />
                   <router-link :to="'/project/'+projectID+'/settings/module'" style="color: #6a6c6f;padding-left: 5px;"  >模块</router-link>
                 </DropdownItem>
                <DropdownItem name="5:WebHooks:webhook"><Icon type="ios-cube" />
                   <router-link :to="'/project/'+projectID+'/settings/webhook'"  style="color: #6a6c6f;padding-left: 5px;" >WebHooks</router-link>
                 </DropdownItem>
              </DropdownMenu>
                </template>
           </Dropdown>
          </span>
        </div>
      </i-col>
    </Row>
  </div>
  </div>
</template>

<script>
  import { mapMutations, mapGetters } from 'vuex'

export default {
  components: {
  },
  name: 'ProjectHead',
  props: ['projectID', 'page'],
  data () {
    return {
      versionList: [],
      projectInfo: {},
      projectPermision: 0,
      lastestVersion: 0,
      statisticsDateRange: '',
      // taskViewMode: 'board',
      settingsView: 1,
      settingsName: '',
      router: {name:''},
      myProjects: [],
      settingsViewName: 'info',
      dateRangeOption: {
        shortcuts: [
          {
            text: '最近一星期',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            }
          },
          {
            text: '最近一个月',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            }
          },
          {
            text: '最近3个月',
            value () {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            }
          }
        ]
      },
    }
  },

  computed: {
    ...mapGetters('issue', ['issueFilters', 'searchKeyword']),
    ...mapGetters('projectglobal', ['projectRole','headMenu','storyFilterPanelShow','rightSidePanelShow']),
    routerName: function () {
      return this.$route.name
    },

    latestMonth: function () {
      const end = this.formatDate(new Date(),'yyyy-MM-dd')
      let start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      let startFormat = this.formatDate(start,'yyyy-MM-dd')
      return [startFormat, end]
    },

    storyFilterColor: function (){
      if(this.storyFilterPanelShow){
        return '#2c35e0'
      } else {
        return ''
      }
    }

  },
  methods: {
    ...mapMutations('task', ['setTaskGanntMaxSize']),
    ...mapMutations(['setItemViewMode']),
    ...mapMutations('systemglobal',['setCreateSystemDialogShow']),
    ...mapMutations('projectglobal', ['setCreateDialogShow','setStoryFilterPanelShow', 'setProjectVersion', 'setRightPanelShow', 'setTaskViewMode','setHeadMenu','setCreateDocumentType','setCreateReqType', 'setDateRange', 'setImportXmindFile']),

    newObject () {
      if(this.$route.params.page === 'webhook'){
        this.setCreateSystemDialogShow(true)
      }else {
        this.setCreateDialogShow(true)
      }

    },
    onVersionChange (value) {
      this.setProjectVersion(value)
    },

    importMindFile: function () {
      this.setImportXmindFile(true)
    },

    changeViewMode: function (value) {
      this.setItemViewMode(value)
    },

    loadMyProject: function () {
        this.$axios.get('/api/project/list?extinfo=0&my=1').then(response => {
          this.myProjects = response.data.result
        }, response => {
        })
    },

    selectDateRange: function () {
      let start = ''
      let end = ''
      if (this.statisticsDateRange[0] !== '') {
        start = this.formatDate(this.statisticsDateRange[0],'yyyy-MM-dd')
        end = this.formatDate(this.statisticsDateRange[1],'yyyy-MM-dd')
      }
      this.setDateRange([start,end])
    },

    showRightPanel () {
        console.log("show task filter")
        if(this.rightSidePanelShow){
            this.setRightPanelShow(false)
        } else {
            this.setRightPanelShow(true)
        }
    },

    showStoryFilterPanel:function (){
      if(this.storyFilterPanelShow){
        this.setStoryFilterPanelShow(false)
      } else {
        this.setStoryFilterPanelShow(true)
      }
    },

    taskViewSwitch (value) {
      this.setItemViewMode(value)
      this.taskViewMode = value
    },

    settingsViewSwitch (value) {
      let viewNames = value.split(':')
      this.settingsView = parseInt(viewNames[0])
      this.settingsName = viewNames[1]
      this.settingsViewName = viewNames[2]
    },

    createDocument: function (value) {
      this.setCreateDocumentType(parseInt(value))
    },

    createReqirement: function (value) {
      this.setCreateReqType(parseInt(value))
    },

    formatDate: function (date,fmt)
    {
      let o = {
        "M+" : date.getMonth()+1,                 //月份
        "d+" : date.getDate(),                    //日
        "h+" : date.getHours(),                   //小时
        "m+" : date.getMinutes(),                 //分
        "s+" : date.getSeconds(),                 //秒
        "q+" : Math.floor((date.getMonth()+3)/3), //季度
        "S"  : date.getMilliseconds()             //毫秒
      };
      if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substring(4 - RegExp.$1.length));
      for(var k in o)
        if(new RegExp("("+ k +")").test(fmt))
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substring((""+ o[k]).length)));
      return fmt;
    },

    exportIssueExcel: function () {

      let realUrl = '/api/project/issue/export?'
      if (this.searchKeyword !== '') {
        realUrl = realUrl + 'Title__icontains=' + this.searchKeyword + '&'
      }

      if (this.issueFilters !== '') {
        realUrl = realUrl + this.issueFilters
      }
      this.$axios({url: realUrl, method: 'get', responseType: 'arraybuffer'}).then(response => {
        let url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.ms-excel'}))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', this.projectID + '.xls')
        document.body.appendChild(link)
        link.click()
        link.remove()
      }, response => {
      })
    },

    loadProjectVersions: function () {
      this.$axios.get('/api/project/' + this.projectID + '/detail?extinfo=1').then(response => {
        let defaultVersion = {'id': 0, 'VersionLabel': '无', 'VProjectID': 0, 'VVersion': '无', 'VStartDate': null, 'VReleaseDate':null, 'VDescription': ''}
        let versions = response.data.result.Versions
        this.versionList = []
        this.projectInfo= response.data.result
        console.log(this.projectInfo)
        this.lastestVersion = response.data.result.LatestVersion
        this.setProjectVersion(this.lastestVersion)
        if (versions.length > 0) {
          this.versionList.push(defaultVersion)
          this.versionList.push(...versions)
        }
      }, response => {

      })
    },

    initSettingsMenu: function (value) {
      if (value) {
        if (value.trim().toLowerCase() === 'info') {
          this.settingsView = 1
          this.settingsName = '项目信息'
          this.settingsViewName = 'info'
        }
        if (value.trim().toLowerCase() === 'version') {
          this.settingsView = 2
          this.settingsName = '版本规划'
          this.settingsViewName = 'version'
        }
        if (value.trim().toLowerCase() === 'member') {
          this.settingsView = 3
          this.settingsName = '成员'
          this.settingsViewName = 'info'
        }
        if (value.trim().toLowerCase() === 'module') {
          this.settingsView = 4
          this.settingsName = '模块'
          this.settingsViewName = 'module'
        }
        if (value.trim().toLowerCase() === 'webhook') {
          this.settingsView = 5
          this.settingsName = 'WebHooks'
          this.settingsViewName = 'webhook'
        }
        if (value.trim().toLowerCase() === 'code-lab') {
          this.settingsView = 6
          this.settingsName = '代码仓库'
          this.settingsViewName = '代码仓库'
        }
      }
    },

    initHeadMenu: function (to) {
      console.log(to)
      if (to.params.page === 'info' || to.params.page === 'module') {
        let headMenu = {
          newObject: false,
          searchBox: false,
          versionBox: false,
          dateRangeBox: false,
          documentNew: false,
          reqNew: false,
          issueExport: false,
          taskViewMode: false,
          taskFilter: false
        }
        this.setHeadMenu(headMenu)
      }

      if (to.params.page === 'memeber' || to.params.page === 'version') {
        let headMenu = {
          newObject: true,
          searchBox: false,
          versionBox: false,
          dateRangeBox: false,
          documentNew: false,
          reqNew: false,
          issueExport: false,
          taskViewMode: false,
          taskFilter: false
        }
        this.setHeadMenu(headMenu)
      }

      if (to.name === 'projectStatistics') {
        let headMenu = {
          newObject: false,
          searchBox: false,
          versionBox: true,
          dateRangeBox: true,
          documentNew: false,
          reqNew: false,
          issueExport: false,
          taskViewMode: false,
          taskFilter: false
        }
        this.setHeadMenu(headMenu)
      }
      if (to.name === 'homeRequirement') {
        let headMenu = {
          newObject: false,
          searchBox: false,
          versionBox: true,
          dateRangeBox: true,
          documentNew: false,
          reqNew: false,
          issueExport: false,
          taskViewMode: false,
          storyFilter:true,
          taskFilter: false
        }
        this.setHeadMenu(headMenu)
      }
      if(to.params.action) {
        let headMenu = {
          newObject: false,
          searchBox: false,
          versionBox: false,
          dateRangeBox: false,
          documentNew: false,
          reqNew: false,
          issueExport: false,
          taskViewMode: false,
          taskFilter: false
        }
        console.log(to)
        if( to.params.action === 'report' ){
          headMenu.newObject = false
        } else {
          headMenu.newObject = false
        }
        this.setHeadMenu(headMenu)
      }
    }

  },

  // beforeRouteEnter (to, from, next) {
  //
  //   next(vm => {
  //     vm.initHeadMenu(to)
  //   })
  // },
  //
  // beforeRouteUpdate (to, from, next) {
  //
  //   if (to.params.page === 'info' || to.params.page === 'module') {
  //     let headMenu = {
  //       newObject: false,
  //       searchBox: false,
  //       versionBox: false,
  //       dateRangeBox: false,
  //       documentNew: false,
  //       reqNew: false,
  //       issueExport: false,
  //       taskViewMode: false,
  //       taskFilter: false
  //     }
  //     this.setHeadMenu(headMenu)
  //   }
  //
  //   if (to.params.page === 'memeber' || to.params.page === 'version' || to.params.page === 'webhook') {
  //     let headMenu = {
  //       newObject: true,
  //       searchBox: false,
  //       versionBox: false,
  //       dateRangeBox: false,
  //       documentNew: false,
  //       reqNew: false,
  //       issueExport: false,
  //       taskViewMode: false,
  //       taskFilter: false
  //     }
  //     this.setHeadMenu(headMenu)
  //   }
  //
  //   if (to.name === 'projectStatistics') {
  //     let headMenu = {
  //       newObject: false,
  //       searchBox: false,
  //       versionBox: true,
  //       dateRangeBox: true,
  //       documentNew: false,
  //       reqNew: false,
  //       issueExport: false,
  //       taskViewMode: false,
  //       taskFilter: false
  //     }
  //     this.setHeadMenu(headMenu)
  //   }
  //
  //   if (to.name === 'homeRequirement') {
  //     let headMenu = {
  //       newObject: false,
  //       searchBox: false,
  //       versionBox: true,
  //       dateRangeBox: true,
  //       documentNew: false,
  //       reqNew: false,
  //       issueExport: false,
  //       taskViewMode: false,
  //       storyFilter:true,
  //       taskFilter: false
  //     }
  //     this.setHeadMenu(headMenu)
  //   }
  //
  //   if(to.params.action) {
  //     let headMenu = {
  //       newObject: false,
  //       searchBox: false,
  //       versionBox: false,
  //       dateRangeBox: false,
  //       documentNew: false,
  //       reqNew: false,
  //       issueExport: false,
  //       taskViewMode: false,
  //       taskFilter: false
  //     }
  //     if( to.params.action === 'test-report' ){
  //       headMenu.newObject = true
  //     } else {
  //       headMenu.newObject = false
  //     }
  //     this.setHeadMenu(headMenu)
  //   }
  //
  //   this.statisticsDateRange = this.latestMonth
  //   this.setDateRange(this.statisticsDateRange)
  //   next()
  // },

  created: function () {
    console.log(this.projectID)
    this.loadMyProject()
    if(this.projectID){
      this.loadProjectVersions()
    }
    this.initSettingsMenu(this.page)
    this.initHeadMenu(this.$router)
    this.statisticsDateRange = this.latestMonth
    this.setDateRange(this.statisticsDateRange)
  },



  watch: {
    projectID: function (value) {
      console.log(this.projectID)
      if(value>0){
        this.loadProjectVersions()
//        this.loadMyProject()
      }
    },
    '$route' (to, from) {
      console.log(to)
      if (to.name !== 'projectRoot' && to.name.indexOf('project') > -1) {
        console.log(this.projectID)
        this.projectID = to.params.projectID
        this.router = to
      } else {
        this.projectID = 0
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@import '../../components/layout/appBody';
@import '../../components/layout/appHead';
@import '../../assets/teamvision/global/less/global';

.app-body-header-rightbar-default span{
  padding-left: 5px;
  padding-right: 5px;
}

</style>
