import Vue from 'vue'
import Vuex from 'vuex'


const state = {
  testCaseGroupTitle: '',
  searchKeyword: '',
  searchCaseKeyword: {type:1,filters:['']}, //type 1: search case by keyword,2 filter case by tag
  caseFilters: {type:1,filters:['']},
  refreshCase: false,
  showCaseID: 0,
  execCase: null,
  testCaseViewMode: 'mindMap'
}
const getters = {
  testCaseGroupTitle (state) {
    return state.testCaseGroupTitle
  },


  searchKeyword (state) {
    return state.searchKeyword
  },

  caseFilters (state) {
    return state.caseFilters
  },

  refreshCase (state) {
    return state.refreshCase
  },

  showCaseID (state) {
    return state.showCaseID
  },
  execCase (state) {
    return state.execCase
  },

  testCaseViewMode(state){
    return state.testCaseViewMode
  }

}
const mutations = {

  setTestCaseGroupTitle (state, title) {
    state.testCaseGroupTitle = title
  },


  setSearchKeyword (state,keyword) {
    state.searchKeyword = keyword
  },

  setCaseFilters (state,filters) {
    state.caseFilters = filters
  },

  setRefreshCase (state,refresh) {
    state.refreshCase = refresh
  },

  setShowCaseID (state,caseID) {
    state.showCaseID = caseID
  },

  setExecCase (state,caseData) {
    state.execCase = caseData
  },

  setTestCaseViewMode (state,mode) {
    state.testCaseViewMode = mode
  }

}
const actions = {
  setTestCaseGroupTitleAction (context, isChanged) {
    context.commit('setTestCaseGroupTitle', isChanged)
  }
}
const modules = {}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
  modules
}
