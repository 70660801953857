//初始化项目以及版本信息

import axios from '../util/TeVisionAixos.js'

import {Message,Modal } from 'view-ui-plus'

let productSpaces = '/api/project/product_spaces'
let spaceUsers = '/api/common/users/list?ReadOnly=1'
let spaceProjectSampleList = '/api/project/list?'
let systemEventList = '/api/common/sevents'
let systemEventModelList = '/api/common/sevent/available_models'
let webhookList = '/api/common/webhooks'
let webhookCreate = '/api/common/webhook/create'
let webhookUpdate = '/api/common/webhook/'
let webhookDelete = '/api/common/webhook/'
let webhookDetail = '/api/common/webhook/'
let webhookEvents = '/api/common/webhook/{HOOKID}/events'
let workflowUserTaskList = '/api/workflow/instance/user_task/list?'
let workflowUserTask = '/api/workflow/instance/user_task/'


let getSpaceList = () => {

  let initPromise = new Promise(function (resolve, reject) {
    axios.get(productSpaces).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let getSpaceUserList = () => {

  let initPromise = new Promise(function (resolve, reject) {
    axios.get(spaceUsers).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}
let getSpaceSampleProjectList = (filter) => {

  let initPromise = new Promise(function (resolve, reject) {
    axios.get(spaceProjectSampleList+filter).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let getWebHookEvents = (id) =>{
  let initPromise = new Promise(function (resolve, reject) {
    axios.get(webhookEvents.replace('{HOOKID}',id)).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let getWebhookList = (filter) => {
  let initPromise = new Promise(function (resolve, reject) {
    axios.get(webhookList+'?'+filter).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let createWebHook = (data) => {
  let initPromise = new Promise(function (resolve, reject) {
    axios.post(webhookCreate,data).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let updateWebHook = (data) => {
  let initPromise = new Promise(function (resolve, reject) {
    axios.put(webhookUpdate+data.id,data).then(response => {
      let initData=response
      resolve(initData)
      Message.success({
        content: 'WebHook更新成功',
        duration: 10,
        closable: true
      })
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let updateWebHookField = (id,data) => {
  let initPromise = new Promise(function (resolve, reject) {
    axios.patch(webhookUpdate+id,data).then(response => {
      let initData=response
      resolve(initData)
      Message.success({
        content: 'WebHook更新成功',
        duration: 10,
        closable: true
      })
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let deleteWebHook = (id) => {
  let initPromise = new Promise(function (resolve, reject) {
    axios.delete(webhookDelete+id).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let getWebHookDetail = (id) => {
  let initPromise = new Promise(function (resolve, reject) {
    axios.get(webhookDetail+id).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let getSystemEventList = () => {

  let initPromise = new Promise(function (resolve, reject) {
    axios.get(systemEventList).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let getSystemEventModelList = () => {

  let initPromise = new Promise(function (resolve, reject) {
    axios.get(systemEventModelList).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let getWorkflowUserTaskList = (filter) => {

  let initPromise = new Promise(function (resolve, reject) {
    axios.get(workflowUserTaskList+filter).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}

let updateWorkflowUserTask = (data) => {

  let initPromise = new Promise(function (resolve, reject) {
    axios.patch(workflowUserTask+data.id,data).then(response => {
      let initData=response
      resolve(initData)
    }, response => {

      reject(response)
    })
  })
  return initPromise
}


export {
  getSpaceList,
  getSpaceUserList,
  getSpaceSampleProjectList,
  getSystemEventList,
  getSystemEventModelList,
  createWebHook,
  updateWebHook,
  deleteWebHook,
  getWebHookDetail,
  getWebhookList,
  getWebHookEvents,
  updateWebHookField,
  getWorkflowUserTaskList,
  updateWorkflowUserTask

}
