import store from '../store/index.js'


import {getSpaceList,getSpaceSampleProjectList,getSpaceUserList } from './TeamvisionApiService'

let initGlobalVars=()=>{
  getSpaceList().then(response=>{
    store.commit('setProductSpaceList',response.data.result)
  },response=>{
  })
  let filter = "extinfo=0&my=0"
  getSpaceSampleProjectList(filter).then(response=>{
    store.commit('setSpaceSimpleProjectList',response.data.result)
  },response=>{
  })
  getSpaceUserList().then(response=>{
    store.commit('setSpaceUserList',response.data.result)
  },response=>{
  })
}



export {
  initGlobalVars
}
