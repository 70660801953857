
import store from '../store/index'
// import EmptyHead from '../components/layout/EmptyBodyHead.vue'

const userCenterRouter = [
  {
    name: 'userCenter',
    path: '/ucenter/:userID/profiles',
    components: {
      default: function () {
        return import('../pages/user-center/profiles/UserProfiles.vue')
      },
      bodyhead: function () {
        return import('../pages/user-center/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu()
      next()
    },
    props: {default: true,bodyhead: true},
    meta: ''
  },
  {
    name: 'userCenterNotification',
    path: '/ucenter/:userID/notification',
    components: {
      default: function () {
        return import('../pages/user-center/UserNotification.vue')
      },
      bodyhead: function () {
        return import('../pages/user-center/Head.vue')
      }
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      initBodyMenu()
      next()
    },
    props: {default: true,bodyhead: true},
    meta: ''
  },
]

function initBodyMenu () {
  let headMenu = {newObject: false,searchBox: false}
  store.commit('ucenterglobal/setHeadMenu',headMenu)
}

function initGlobalMenu (to) {
  let headMenu = {text: '个人中心',path: to.path,icon: 'fa-user',parameters: {},show: true}
  store.commit('setDynamicMenu',headMenu)
}

export {
  userCenterRouter
}
