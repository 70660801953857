import Vue from 'vue'
import Vuex from 'vuex'


const state = {
  headMenu: {
    newObject: true,
    searchBox: true
  },

  createDialogShow: false,
  systemAdminearchKey: ''
}
const getters = {
  headMenu (state) {
    return state.headMenu
  },

  createDialogShow (state) {
    return state.createDialogShow
  },

  systemAdminearchKey (state) {
    return state.systemAdminearchKey
  },
}
const mutations = {

  setCreateDialogShow (state, isShow) {
    state.createDialogShow = isShow
  },

  setHeadMenu (state, headMenu) {
    state.headMenu = headMenu
  },
  setSystemAdminearchKey (state, searchKey) {
    state.systemAdminearchKey = searchKey
  }
}
const actions = {
  setHeadMenuAction (context, headMenu) {
    context.commit('setHeadMenu', headMenu)
  }
}
const modules = {}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
  modules
}
