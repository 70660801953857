// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import Vue from 'vue'
import { createApp } from 'vue'
import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import App from './App.vue'
import axios from './util/TeVisionAixos.js'
import VueAxios from 'vue-axios'
import router from './router'
import store from './store'
import './assets/teamvision/global/less/global.less'
import './assets/teamvision/global/less/MyIviewTheme.less'
import JSZip from 'jszip/dist/jszip.min.js'
import cookie from "js-cookie"
import kityminder from 'vue-kityminder-editor'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import mitt from 'mitt'
import { initGlobalVars } from './business-service/InitService'
const app = createApp({
  ...App})
const emitter = mitt()
app.config.globalProperties.$emitter = emitter;
app.config.errorHandler = (err, vm, info) => {
  // 处理错误
  // err: 错误对象
  // vm: 发生错误的 Vue 实例
  // info: 一个包含错误来源信息的字符串（例如："render" 或 "watch"）

  console.info('Caught an error:', err);
  console.info('Error details:', info);

  // 你可以在这里执行其他操作，比如发送错误报告到服务器
};

app.use(ElementPlus)
app.use(store)
app.use(router)
// app.use(VueAxios)


// axios.defaults.withCredentials = true
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

window.JSZip = JSZip

app.config.globalProperties.$axios = axios
app.config.globalProperties.$cookie = cookie
app.provide('axios', axios);
// app.config.productionTip = false
app.use(ViewUIPlus)
app.use(kityminder)

router.beforeEach((to, from, next) => {
  // ViewUI.LoadingBar.start()
  console.log('beforeeach')
  console.log(to)
  if ( to.name !== 'userLogin' && to.name !== 'projectIssueMobileUpload' && to.name !== 'userLogout') {
    if (!localStorage.getItem('login')) {
      store.commit('setLogin',false)
      // ViewUI.LoadingBar.finish()
      console.log(to)
      next({path: '/user/login',query:{redirect: to.fullPath}})
    } else {
      store.commit('setLogin',true)
      initGlobalVars()
      let userInfo = sessionStorage.getItem('userInfo')
      if(!userInfo){
        loadUserInfo(from ,to ,next)
      } else {
        store.commit('setUserInfo',JSON.parse(userInfo))
        store.commit('setProductSpace',JSON.parse(userInfo).ViewData.default_space.id)
        store.commit('setLogin',true)
        store.commit('setAppBodyTop','75')
        next()
      }
    }
  } else {
    // ViewUI.LoadingBar.finish()
    next()
  }
})


function loadUserInfo (from, to, next) {
  axios.get('/api/common/user/0').then(response => {
    if (response.data.result.id != null) {
      store.commit('setProductSpace',response.data.result.ViewData.default_space.id)
      store.commit('setUserInfo',response.data.result)
      store.commit('setLogin',true)
      store.commit('setAppBodyTop','75')
      sessionStorage.setItem('userInfo',JSON.stringify(response.data.result))
    } else {
        store.commit('setLogin',false)
        next({path: '/user/login'})
    }
    next()
  }, response => {
    next({path: '/'})
  })
}

router.afterEach(route => {
})

app.mount('#teamvision')
