import store from '../store/index'

import EmptyBodyHead from "@/components/layout/EmptyBodyHead.vue";


const knowledgeRouter = [

  {
    name: 'knowledgeRoot',
    path: '/knowledge',
    components: {
      noHeaderBody: function () {
        return import('../pages/knowledge/Knowledge.vue')
      },
      bodyhead: EmptyBodyHead
    },
    props: {bodyhead: true, noHeaderBody: true},
    meta: '',
    beforeEnter: (to, from, next) => {
      let headMenu = {
        newObject: true,
        itemViewMode: true,
        filterMenu: true,
        exportIssue: false
      }
      hideDynamicHomeMenu()
      initBodyMenu(headMenu)
      next()
    }
  },
]


function initDynamicHomeMenu (to,menuText,isshow) {
  let headMenu = {text: menuText,path: to.path,icon: 'fa-cogs',parameters: {},show: isshow}
  store.commit('homeglobal/setHomeDynamicMenu',headMenu)
}

function hideDynamicHomeMenu () {
  let headMenu = {text: '',path: '',icon: 'fa-cogs',parameters: {},show:false}
  store.commit('homeglobal/setHomeDynamicMenu',headMenu)
}

function initBodyMenu (headMenu) {
  store.commit('homeglobal/setHeadMenu',headMenu)
}

export {
  knowledgeRouter
}
