import Vue from 'vue'
import Vuex from 'vuex'


const state = {
  requirementFilters: {
    status: [],
    keyword: '',
  },
}
const getters = {

  requirementFilters (status) {
    return state.requirementFilters
  },

  requirementFilterStatus (state) {
    return state.requirementFilters.status
  },

  requirementFilterKeyword (state) {
    return state.requirementFilters.keyword
  },

}
const mutations = {


  setRequirementFilterStatus (state, status) {
    state.requirementFilters.status = status
  },

  setRequirementFilterKeyword (state, keyword) {
    state.requirementFilters.keyword = keyword
  },
}
const actions = {
}
const modules = {}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
  modules
}
