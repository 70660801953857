import Vue from 'vue'
import Vuex from 'vuex'


const state = {
  showUserDetail: false
}
const getters = {
  showUserDetail (state) {
    return state.showUserDetail
  }
}
const mutations = {
  setShowUserDetail (state, showUserDetail) {
    state.showUserDetail = showUserDetail
  }
}
const actions = {

}
const modules = {}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
  modules
}
