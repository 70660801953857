
import store from '../store/index'
import EmptyHead from '../components/layout/EmptyBodyHead.vue'
import UnloginBody from '../pages/login/Login.vue'

const loginoutRouter = [
  {
    name: 'Home',
    path: '/',
    components: {
      unLoginBody: function () {
        return import('../pages/login/Login.vue')
      },
      bodyhead: EmptyHead,
    },
    beforeEnter: (to, from, next) => {
      let login = store.getters['login']
      initGlobalMenu(to)
      if (login) {
        next({path: '/home/summary'})
      } else {
        next()
      }
    },
    props: {default: true,bodyhead: true},
    meta: ''
  },
  {
    name: 'userLogin',
    path: '/user/login',
    components: {
      unLoginBody: UnloginBody,
      unloginbodyhead: EmptyHead,
    },

    beforeEnter: (to, from, next) => {
      initGlobalMenu(to)
      next()
    },
    props: {unLoginBody:true,unloginbodyhead:true},
    meta: ''
  },

  {
    name: 'userLogout',
    path: '/user/logout',
    components: {
      unLoginBody: UnloginBody,
      unloginbodyhead: EmptyHead,
    },

    beforeEnter: (to, from, next) => {
      next()
    },
    props: {unLoginBody:true,unloginbodyhead:true},
    meta: ''
  },
]

// function initBodyMenu () {
//   let headMenu = {newObject: false,searchBox: false}
//   store.commit('ucenterglobal/setHeadMenu',headMenu)
// }

function initGlobalMenu (to) {
  let headMenu = {text: '个人中心',path: to.path,icon: 'fa-user',parameters: {},show: false}
  store.commit('setDynamicMenu',headMenu)
  store.commit('setAppBodyTop', '0')
}

export {
  loginoutRouter
}
