import Vue from 'vue'
import Vuex from 'vuex'


const state = {
  breadNav: [{id: '', FileName: 'Home'}],
  editDocument: false
}
const getters = {
  breadNav (state) {
    return state.breadNav
  },
  editDocument (state) {
    return state.editDocument
  }

}
const mutations = {

  setbreadNav (state, navID) {
    let result = [{id: '', FileName: 'Home'}]
    if (navID[0] !== '') {
      let navIndex = -1
      for (let i = 1; i < state.breadNav.length; i++) {
        if (navID[0] === state.breadNav[i].id) {
          navIndex = i
          break
        }
      }
      if (navIndex === -1) {
        result = state.breadNav
        let temp = {}
        temp.id = navID[0]
        temp.FileName = navID[1]
        result.push(temp)
      } else {
        for (let i = 1; i < navIndex + 1; i++) {
          result.push(state.breadNav[i])
        }
      }
    }
    state.breadNav = result
  },

  setEditDocument (state,isEdit) {
    state.editDocument = isEdit
  }
}
const actions = {
  setTaskChangeAction (context, isChanged) {
    context.commit('setTaskChange', isChanged)
  }
}
const modules = {}

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
  modules
}
