<template>
  <div v-show="!login" :style="'height:'+containerHeight+'px;overflow-y:auto;'">
    <Row style="height: inherit;">
      <i-col span="9" style="">
        <div style="text-align:center;width: 300px; margin-left: auto;margin-right: auto;margin-top:200px">
          <div style="width: 150px">
            <img src="../../assets/teamvision/global/images/logo.jpg" style="width: 60px;height: 60px; border-radius: 5px;" />
          </div>
          <div style="text-align: left">
            <span class="appName">Teamvision</span>
          </div>
          <div class="slogan">
            <ul>
              <li>
                <div>
                  <Icon size="18" type="md-checkmark" />
                  <span>需求协作</span>
                </div>
                <div class="slogan-item-desc">
                  <span>需求生命周期管理，度量</span>
                </div>
              </li>
              <li>
                <div>
                  <Icon size="18" type="md-checkmark" />
                  <span>项目管理</span>
                </div>
                <div class="slogan-item-desc">
                  <span>需求，Bug,任务，测试过程管理度量</span>
                </div>
              </li>
              <li>
                <div>
                  <Icon size="18" type="md-checkmark" />
                  <span>代码构建</span>
                </div>
                <div class="slogan-item-desc">
                  <span>代码仓库，构建任务，流水线，发布管理</span>
                </div>
              </li>
            </ul>
          </div>
        </div>

      </i-col>
      <i-col span="15" style="height:inherit;background-color: #FFFFFF;">
        <div :style="'text-align:center;width: 400px; margin-left: auto;margin-right: auto;margin-top:200px'">
          <Card class="user_login_dialog" :bordered="false" dis-hover>
            <Row type="flex" justify="center" >
              <i-col>
                <div style="line-height: 30px;">
                  <span style="font-size: 24px;letter-spacing: 3px;">
                  登录
                  </span>
                </div>
                <div style="line-height: 30px;">
                  <span style="font-size: 12px;color:#323A45">
                    登录到工作台
                  </span>
                </div>
              </i-col>
            </Row>
            <Form ref="login" label-position="top" :model="loginForm" :label-width="0" class="login-form" :rules="validateRules">
              <FormItem label="" prop="email">
                <Input   size="large"  v-model="loginForm.email" placeholder="登录邮箱"/>
              </FormItem>
              <FormItem label="" prop="Title">
                <Input  type="password" size="large"  v-model="loginForm.password" placeholder="登录密码"/>
              </FormItem>
              <Button class="login-button2"  long @click="loginSystem">登录</Button>
            </Form>

          </Card>
        </div>
      </i-col>
    </Row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { loginValidateRules } from './login'
import axios from "axios";

export default {
  name: 'HomeUnlogin',
  props: ['loginType'],
  data () {
    return {
      loginForm: {
        email: 'teamvision@teamvision.cn',
        password: '123456'
      },
      validateRules: loginValidateRules
    }
  },
  computed: {
    ...mapGetters(['appBodyHeight', 'userInfo','login']),
    ...mapGetters('systemglobal', ['createSystemDialogShow']),
    containerHeight: function () {
      return this.appBodyHeight
    },

    routerName: function () {
      return this.$route.name
    },
    loginBackground: function () {
      let random_num = Math.floor(Math.random() * (10 - 1)) + 1
      return 'url(/static/global/images/login/login' + random_num + '.jpg)'
    }
  },


  methods: {
    ...mapMutations(['setLogin', 'setUserInfo']),
    ...mapMutations('systemglobal', ['setCreateSystemDialogShow', '']),

    updateProfiles: function () {

    },

    changeAvatar: function () {

    },


    loginSystem: function () {
      sessionStorage.removeItem('userInfo')
      this.$axios.post('/api/common/user/login', this.loginForm).then(response => {
        if (!response.data.result.login) {
          this.$Message.error({
            content: response.data.result.message,
            duration: 10,
            closable: true
          })
        } else {
          this.$cookie.set('sessionid', response.data.result.sessionid)
          localStorage.setItem('login', true)
          this.get_access_token()
          if(this.$route.query.redirect){
            this.$router.push({ path: this.$route.query.redirect })
          }else{
            this.$router.push({ path: '/home' })
          }
        }
      }, response => {
        console.log(response)
        this.$Message.error({
          content: "用户名密码错误",
          duration: 10,
          closable: true
        })
      })
        return true
    },

    get_access_token: function() {
      let client_credentials = 'Basic V3FXaGlid0dMdXd4Qk5yNEI3NG9vN2FHdlpTZzlsRkgzWDJBdW8wajpUVFZmUENFT3JqeWJsbTk5d0xNSEhVUUUzb0kzTG9Sd3JPSnoxdEtTVG53R2M2OGM2ZHhxS3FpOHlZUzZKckZnYW8ySk5OMjRubU5MZjZxZTkxa0Z4ZGxhaHpCMEFUOXdQR0lXeThxbWxqVFAzV0tJSXpWdnpaYVVxcmZWaDA5Rw=='
      let headers = {}
      headers['Content-Type'] = 'application/x-www-form-urlencoded'
      headers['Authorization'] = client_credentials
      let parameters = {grant_type:'client_credentials'}
      let options = {
        method: 'POST',
        headers: headers,
        data: 'grant_type=client_credentials',   // 用 qs 将js对象转换为字符串 'name=edward&age=25'
        url: '/api/o/token/'
      }
      axios(options).then(response => {
        if(response.status ===200) {
          localStorage.setItem('token','Bearer '+response.data.access_token)
        }
      }, response => {
        this.$Message.error({
          content: '权限获取失败',
          duration: 10,
          closable: true
        })
      })
    }
  },

  created: function () {
  },

  mounted: function () {

  },

  watch: {
  },

  components: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

  .slogan {
    font-family: '幼圆' !important;
    //font-size: 35px !important;
    margin-bottom: 25px;
    display: block;
    font-weight: 400;
    //text-align: center;
    letter-spacing: 6px;
    color: #323A45;
  }

  .slogan ul{
    text-align: left;
    list-style: none;
  }

  .slogan ul li{
    line-height: 40px;
  }

  .slogan-item-desc{
    margin-left: 35px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #323A45;
  }

  .appName {
    font-family: 'Dotum' !important;
    font-size: 24px;
    font-weight: 400;
    text-align: left;
    color: #323A45;
    width: 270px;
    letter-spacing: 2px;
  }

  .user_login_dialog {
    width: 480px;
    height: 360px;
    border-radius: 10px;
    background: #FFFFFF;
    color: #0C0C0C;
  }

  .login-form {
    width: 100%;
    height: 100%;
    //margin-top: -130px;
    padding: 20px 50px 50px 50px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: absolute;
    color: #0C0C0C;
  }

  .login-button2 {
    background-color: #515a6e!important;
    /*border-radius: 10px;*/
    border-color: #202d40;
    width: 100%;
    font-size: 14px;
    color: #f0f5ff;
    height: 48px;
    padding-top: 0px;
    margin-top: 30px;
  }

  .ivu-input-large {
    font-size: 14px;
    padding: 6px 7px;
    height: 48px;
  }

</style>

<style lang="less">

  .login-form .ivu-input-large {
    font-size: 16px;
    padding: 6px 7px;
    height: 48px;
  }

</style>
