//初始化项目以及版本信息

let loginValidateRules={
  email: [
    { type: 'email', required: true, message: '请输入邮箱' }
  ]
}

export {
  loginValidateRules
}
